import json from "./assets/data/marketplaces";
import axios from 'axios';

export default {
	data() {
		return {
			localCity: null,
		}
	},
	computed: {
		marketplaces() {
			let mps = {};
			json.forEach(element => {
				mps[element.name] = element;
			});

			return mps;
		}
	},
	methods: {
		buildQuery(mp) {
			let result 		= '';
			let params 		= [];
			const product	= this.$store.getters['getProduct'];

			params.push(mp.query);

			if (product) {
				params.push(`${mp['search_property']}=${product}`);
			}

			result = params.join('&');

			return `${mp.link}${result}`;
		},
		async getLocationByClientIP() {
			if (localStorage.getItem("location")) {
				this.localCity = JSON.parse(localStorage.getItem("location")).name;
				return;
			}
		
			let IP = null;
		
			if (robotDetection.value == false) {
				await axios.get(import.meta.env.VUE_APP_CLIENT_IP).then((res) => (IP = res.data));
			}
		
			if (null == IP) {
				return;
			}
		
			let response = null;
			await axios.get(`${import.meta.env.VUE_APP_GEO_URL}/${import.meta.env.VUE_APP_GEU_KEY}/json/${IP}`).then((res) => (response = res.data));
		
			if (response) {
				this.localCity = response.city.name_ru;
				localStorage.setItem(
					"location",
					JSON.stringify({
						lat: response.city.lat,
						lon: response.city.lon,
						name: response.city.name_ru,
					})
				);
			}
		}
	},
	mounted() {
		this.getLocationByClientIP();
	}
}