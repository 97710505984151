<template>
  <div class="uk-offcanvas-bar header_menu-modal">
    <div
      class="header"
      :class="{'header_focus': sfocus}"
    >
      <div class="uk-container uk-container-large">
        <div class="header__container">
          <div class="logo header__logo">
            <div class="logo__image">
              <a href="/">
                <img src="@/assets/img/logo.svg" alt="eikosha"/>
              </a>
            </div>
          </div>

          <div class="header__group">
            <!-- <div class="header-menu">
              <div class="header-menu__container">
                <div
                    v-for="(item, i) in menu"
                    :key="i"
                    class="header-menu__item">
                  <a
                    :href="item.href"
                    :class="{ 'active' : !$route.path.indexOf(item.href) }"
                  >
                    {{ item.title }}
                  </a>
                </div>
              </div>
            </div> -->

            <div class="header-button">
              <button
                class="uk-button uk-button-default pulse"
                uk-toggle="target: #modal"
              >
                {{ 'Купить на маркетплейсе' }}
              </button>
            </div>

            <div class="header-search">
              <div class="header-search__button">
                <button
                    @click="search($event)"
                    class="btn-search"
                    aria-label="Поиск"
                >
                  <div class="eik-i eik-i-search"></div>
                </button>
                <input
                    type="text"
                    ref="input-search-mob"
                    class="input-search"
                    placeholder="Поиск на сайте"
                    @focus="sfocus = true"
                    @blur="sfocus = false"
                    @keypress.enter="search($event)"
                />
              </div>
            </div>
            <div class="header-menu__btn">
              <div class="header-search__button">
                <button
                    type="button"
                    uk-toggle="target: #offcanvas-overlay"
                    class="btn-menu"
                    aria-label="Закрыть меню"
                >
                  <div class="eik-i eik-i-cross" uk-close></div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-container uk-container-large uk-margin-medium-top">
      <div class="header-button">
        <p>Вся продукция на сайте маркетплейсов</p>
         <button
                class="uk-button uk-button-default pulse"
                uk-toggle="target: #modal"
            >
              {{ 'Купить на маркетплейсе' }}
            </button>
      </div>

      <div class="header-menu uk-margin-medium-top">
        <div class="header-menu__container">
          <div
              v-for="(item, i) in menu"
              :key="i"
              class="header-menu__item">
            <a
              :href="item.href"
              :class="{ 'active' : !$route.path.indexOf(item.href) }"
            >
              {{ item.title }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuJson from '@/assets/data/menu.json'
import UIkit from 'uikit'

export default {
  name: 'header-component',
  data() {
    return {
      menu: MenuJson,
      sfocus: false,
    }
  },
  methods: {
    search() {
      let input = this.$refs['input-search-mob']

      if (input) {
        input.focus()
        if (input.value.length > 2 && this.$route.query.text !== input.value) {
          this.$router.push({name: 'search', query: {text: input.value}})
          input.value = ''
          input.blur()
          UIkit.offcanvas('#offcanvas-overlay').toggle()
        }
      }
    }
  }
}
</script>