<template>
  <div class="main page">
      <div class="section section__header">
        <div
            class="section__background"
            :class="{'section_anim' : backgroundAnim }"
            v-rellax="{speed: -3 }">
          <img
              v-if="backgroundNext"
              :src="require(`@/assets/img/backgrounds/${backgroundNext.file}`)"
              alt="img"
          />
          <img
              v-if="background"
              :src="require(`@/assets/img/backgrounds/${background.file}`)"
              alt="img"
          />
        </div>
        <div class="homepage">
          <div class="homepage__container">
            <div class="homepage__title" :class="{'homepage_fullsize' : animStart}">
              <div class="animated_slow" :class="{'fadeInLight': animStart}">
                Путешествуй с
              </div>
              <div class="name animated_slow" :class="{'fadeInLight': animStart}">
                <svg width="520" height="66" viewBox="0 0 520 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.6573 37.4443H59.2677V26.1983H11.6573V11.2826H61.1144V0.0366211H58.8638H0V64.9081H61.8647V53.7213H11.6573V37.4443Z" fill="white" fill-opacity="1"/>
                  <path d="M74.9067 0.0366211V64.9081H87.4297V0.0366211H85.179H74.9067Z" fill="white" fill-opacity="1"/>
                  <path d="M177.688 0.0366211H170.532H156.797C156.797 0.0366211 120.383 25.4288 119.286 26.1983H112.534V0.0366211H100.415V65.0265L112.534 64.9081V37.5034H120.267C121.364 38.2137 161.125 64.9081 161.125 64.9081H182.882C182.882 64.9081 137.869 34.8399 132.963 31.5845C137.753 28.1515 177.688 0.0366211 177.688 0.0366211Z" fill="white" fill-opacity="1"/>
                  <path d="M233.954 0.0377124H212.948H211.852C203.83 -0.139855 192.634 0.156091 187.902 4.35853C182.189 9.15286 180.977 19.6294 180.977 27.5015V39.1618C180.977 47.5075 182.362 53.2489 185.363 57.9248C189.518 64.4949 200.829 65.1459 207.235 64.9092H207.466H227.722H229.972C239.841 65.0276 249.882 64.7316 254.268 60.47C259.577 55.6757 260.732 46.9156 260.732 40.5232V27.9159C260.732 17.2618 259 10.2183 255.249 5.83826C251.902 1.93177 244.977 -0.0214769 233.954 0.0377124ZM248.382 30.6978V38.6291C248.382 45.6727 247.343 49.9935 245.208 51.8283C243.823 53.0713 238.687 53.6632 230.03 53.5448H228.645H212.025C203.08 53.5448 197.54 53.1897 195.866 51.1773C194.597 49.6383 193.269 44.6072 193.269 38.3924V29.0405C193.269 22.648 193.269 17.5577 196.27 13.4737C197.886 11.2245 204.119 11.2245 209.082 11.2837H210.582H232.743C237.532 11.2837 242.842 11.4612 245.266 13.4737C248.382 16.2556 248.382 24.2461 248.382 30.6978Z" fill="white" fill-opacity="1"/>
                  <path d="M332.407 29.0986C328.079 26.5534 319.711 26.1983 319.653 26.1983H293.107H291.664C288.201 26.2575 286.355 26.1983 284.912 24.4818C283.931 23.4164 283.527 22.2326 283.469 20.0426V19.1548V18.3853C283.527 16.0177 283.584 14.8339 284.681 13.5318C285.835 12.1704 288.374 11.2826 297.492 11.2826H313.074H313.651C321.153 11.1642 323.923 12.2888 324.962 13.1766C326.059 14.1829 326.52 15.3075 326.52 17.0239V22.1734H338.409V17.675C338.524 12.8807 337.427 9.92123 334.196 5.83717C329.694 0.155 319.307 0.0958104 310.939 0.0366211H309.034H301.359C290.914 0.0366211 284.162 0.746892 280.872 2.22662C273.024 5.95555 271.869 12.8215 271.696 19.0956V21.1672C271.869 28.2107 274.986 32.8867 281.103 35.5502C284.739 37.2075 290.279 37.4443 293.222 37.4443H318.672H319.826C322.423 37.4443 325.424 37.3851 326.52 38.3913C328.367 40.167 328.54 42.1202 328.54 44.9613V47.2105C328.54 48.8678 328.079 49.874 326.924 50.9986C325.078 52.7743 318.095 53.6621 312.151 53.6621H302.167H300.551C294.896 53.6621 286.412 53.7213 284.969 51.2354C284.219 50.0516 283.931 48.7494 283.931 46.7962V41.2916H272.216V47.3881C272.216 49.9924 272.62 56.3848 276.602 59.6994C283.123 65.1448 291.375 64.9081 294.434 64.8489H295.126H309.034L312.67 64.9081C320.345 65.0265 329.06 65.204 334.888 60.1137C338.755 56.8583 340.371 52.8927 340.371 46.9145V43.5407C340.313 36.7932 337.831 32.1764 332.407 29.0986Z" fill="white" fill-opacity="1"/>
                  <path d="M412.45 0.0366211V26.1983H362.82V0.0366211H351.163V64.9081H362.82V37.4443H412.45V64.9081H424.685V0.0366211H422.434H412.45Z" fill="white" fill-opacity="1"/>
                  <path d="M481.817 0.0366211H480.548H469.756L433.63 64.9081H447.249C447.249 64.9081 453.424 53.9581 454.463 52.064H497.687C498.784 53.8989 505.536 64.9081 505.536 64.9081H519.733L481.817 0.0366211ZM490.358 40.6405H460.061C462.773 35.787 472.18 18.918 474.892 14.0645C477.72 18.8588 487.531 35.6686 490.358 40.6405Z" fill="white" fill-opacity="1"/>
                </svg>
              </div>
            </div>
            <span
                class="animated_slow_delay"
                  :class="{'fadeIn': animStart}"
                  v-if="background"
                  v-html="background.title"
            >
            </span>
          </div>
        </div>
      </div>

      <div class="section section__main">
        <div class="uk-container uk-container-large">
          <div class="section__container">
            <template v-if="categories">
              <div
                  class="section__slide"
                  v-for="(item, index) in categories"
                  :key="index"
              >
                <slider-main
                    :item="item"
                    :slideIndex="index"
                    :isDevice="isDevice"
                />
              </div>
            </template>

            <div v-if="isDevice !== 'mobile'" class="section__banners banners-top">
              <div class="banner_show animated_fast" data-scroll="true">
                <img
                    src="@/assets/img/banner_bg.png"
                    alt="banner"
                />
              </div>
              <div class="banner_show banner_sm animated" data-scroll="true">
                <img
                    src="@/assets/img/banner_sm.png"
                    alt="banner"
                />
              </div>
            </div>

            <div v-if="packages" class="section__packages">
              <div class="packages__head">
                {{ 'Вид упаковки' }}
              </div>
              <div class="packages__items">
                <a
                    :href="`/catalog?package=${pack.id}`"
                    v-for="(pack, pi) in packages"
                    :key="pi" class="packages__item"
                   
                >
                  <div class="packages__front">
                    <div class="packages__image">
                      <img
                          v-if="pack.file"
                          :src="getImage('packages', pack.file)"
                          :alt="pack.file"
                      />
                    </div>
                    
                    <div 
                        class="packages__newest" 
                        v-if="pack.newest"
                      >
                        Новинка
                    </div>
                    <div class="packages__title">
                      {{ pack.title }}
                    </div>
                  </div>

                  <div class="packages__back">
                    <div class="packages__image">
                      <img
                          v-if="pack.back"
                          :src="getImage('packages', pack.back)"
                          :alt="pack.back"
                      />
                    </div>
                    <div class="packages__title">
                      {{ pack.title }}
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div v-if="accessories" class="section__accessories">
              <div class="accessories__head">
                {{ 'Аксессуары' }}
              </div>
              <div class="accessories__items">
                <div
                    v-for="(access, ai) in accessories"
                    class="accessories__item"
                    @click="marketplaceSearch(access.article)"
                    uk-toggle="#modal"
                    :key="ai"
                >
                  <div class="accessories__image">
                    <img
                        v-if="access.fileDefault"
                        :src="getImage('accessories', imagePath(access))"
                        :alt="access.fileDefault"
                    />
                  </div>
                  <div class="accessories__title">
                    {{ access.title }}
                  </div>
                  <div class="accessories__info">
                    <div class="accessories__package">
                      {{ 'Для мелового ароматизатора' }} <br>
                      <span>{{ access.package }}</span>
                    </div>

                    <div class="accessories__article">
                      <span>Артикул: {{ access.article }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="isDevice !== 'mobile'" class="section__banners banners-bottom">
              <div class="banner_show banner_md animated_fast" data-scroll="true">
                <img
                    src="@/assets/img/banner_md.png"
                    alt="banner"
                />
              </div>
            </div>

          </div>
        </div>
      </div>

      <MouseAnim :theme="backgroundTheme ? backgroundTheme : ''"/>
  </div>
</template>

<script>
import { debounce } from "debounce"
import utils from '../utils'
import MouseAnim from '../components/MouseAnim'
import SliderMain from '../components/SliderMain'
import BackgroundsJson from '@/assets/data/backgrounds.json'

export default {
  name: 'main-page',
  components: {
    MouseAnim,
    SliderMain,
  },
  props: {
    isMobile: null,
    isDevice: null,
  },
  data() {
    return {
      categories: null,
      packages: null,
      accessories: null,
      fetchPath: {
        slider: `slider?sort=true`,
        package: `packages`,
        accessories: `accessories?rand=2&optimize=true`
      },
      backgroundInterval: 4000,
      background: null,
      backgroundNext: null,
      backgroundAnim: false,
      backgroundTheme: null,
      lastRandIndex: -1,
      scrollElements: null,
      animStart: false
    }
  },
  computed: {
    backgrounds() {
      return BackgroundsJson.filter(item => {
        return item.route === this.$route.path
      })
    },
    appName() {
      return process.env.VUE_APP_NAME
    }
  },
  methods: {
    marketplaceSearch(product) {
      this.$store.commit('setProduct', product);
    },
    handleScroll: debounce(function() {
      this.scrollElements?.forEach((item) => {
        if (window.scrollY > item.offsetTop - 155 && !item.classList.contains('.slideInUpShort')) {
          item.classList.add('slideInUpShort')
        }
      });
    }, 100),
    async getInitDataSliders() {
      const {fetchData} = utils()
      const response = await fetchData('get', this.fetchPath.slider)
      this.categories = response ?? null
    },
    async getInitDataPackages() {
      const {fetchData} = utils()
      const response = await fetchData('get', this.fetchPath.package)
      this.packages = response?.data
    },
    async getInitDataProducts() {
      const {fetchData} = utils()
      const response = await fetchData('get', this.fetchPath.accessories)
      this.accessories = response?.data
    },
    getImage(type, name) {
      const { getStaticImage } = utils()
      return getStaticImage(type, name)
    },
    imagePath(product) {
      return String(product.id).concat('/', product.fileDefault)
    },
    updateBackground() {
      if (this.backgrounds) {
        let randIndex = null

        do {
          randIndex = Math.floor(Math.random() * this.backgrounds.length)
        } while(this.lastRandIndex === randIndex)

        this.lastRandIndex = randIndex
        return this.backgrounds[randIndex]
      }
      return null
    },
    dynamicBackgrounds() {
      this.background = this.backgroundNext;
      this.backgroundAnim = true
      const delay = 1500

      setTimeout(() => {
        this.backgroundNext = this.updateBackground()
      }, delay)

      setTimeout(() => {
        this.backgroundAnim = false
        this.backgroundTheme = this.backgroundNext.theme
        this.$emit('updateHeaderTheme', {theme: this.backgroundNext.header})
      }, this.backgroundInterval)

      setTimeout(() => {
        this.dynamicBackgrounds()
      }, this.backgroundInterval + delay)
    }
  },
  mounted() {
    this.getInitDataSliders()
    this.getInitDataPackages()
    this.getInitDataProducts()

    if (this.backgrounds.length) {
      if (this.backgrounds.length > 1) {
        this.backgroundNext = this.updateBackground()
        this.backgroundTheme = this.backgroundNext.theme
        this.$emit('updateHeaderTheme', {theme: this.backgroundNext.header})

        this.dynamicBackgrounds()
        return 1
      }
      this.background = this.backgrounds[0]
      this.backgroundAnim = true
      this.backgroundTheme = this.background.theme
      this.$emit('updateHeaderTheme', {theme: this.background.header})
    }
    this.$nextTick(() => {
      window.addEventListener('scroll', this.handleScroll);
      this.animStart = true
    });
  },
  updated() {
    this.scrollElements = document.querySelectorAll('[data-scroll="true"]')
  },
  beforeDestroy() {
    this.scrollElements = null
    window.removeEventListener('scroll', this.handleScroll)
  },
  metaInfo: {
    title: process.env.VUE_APP_NAME,
    titleTemplate: `%s - ${process.env.VUE_APP_DESCRIPTION}`,
  },
}
</script>