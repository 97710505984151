var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loadInitData)?_c('div',{class:{
      section__filtration: !_vm.header,
      header__filtration: _vm.header,
      black: _vm.black
    }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTab('type')),expression:"showTab('type')"}],staticClass:"container__filters",class:{'animated slideInUpShort' : !_vm.header, 'animated slideInDown' : _vm.header}},[(!_vm.mobile && _vm.device != 'tablet')?_c('div',{staticClass:"filters filters__title"},[_vm._v(" По типу упаковки: ")]):(_vm.device === 'tablet')?_c('div',{staticClass:"filters filters__title"},[_c('p',{class:{ 'active' : _vm.curTab === 'type' },on:{"click":function($event){_vm.curTab = 'type'}}},[_vm._v("По типу упаковки:")]),_c('p',{class:{ 'active' : _vm.curTab === 'category' },on:{"click":function($event){_vm.curTab = 'category'}}},[_vm._v("По аромату:")])]):_c('div',{staticClass:"filters filters__selected",on:{"click":function($event){$event.stopPropagation();_vm.dropdown = _vm.openDropdown('packageType')}}},[_c('p',{staticClass:"uk-text-truncate"},[_vm._v(" "+_vm._s(_vm.packageTypeFilter.title)+" ")]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","fill":"none","stroke":"#262626","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('path',{attrs:{"d":"M6 9l6 6 6-6"}})])]),_c('div',{staticClass:"filters__types",class:{
        'filters__dropdown' : _vm.mobile,
        'active' : _vm.dropdown === 'packageType'
      }},[_vm._l((_vm.packageTypes),function(item,index){return [(_vm.includesType(item))?_c('button',{key:index,staticClass:"filters filters__type",class:{ 'filters__type-active' : item.id === _vm.packageTypeFilter.id },on:{"click":function($event){return _vm.changeFilter('type', item)}}},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e()]})],2)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTab('category')),expression:"showTab('category')"}],staticClass:"container__filters",class:{'animated_slow slideInUpShort' : !_vm.header, 'animated slideInDown' : _vm.header}},[(!_vm.mobile && _vm.device != 'tablet')?_c('div',{staticClass:"filters filters__title"},[_vm._v(" По аромату: ")]):(_vm.device === 'tablet')?_c('div',{staticClass:"filters filters__title"},[_c('p',{class:{ 'active' : _vm.curTab === 'type' },on:{"click":function($event){_vm.curTab = 'type'}}},[_vm._v(" По типу упаковки: ")]),_c('p',{class:{ 'active' : _vm.curTab === 'category' },on:{"click":function($event){_vm.curTab = 'category'}}},[_vm._v(" По аромату: ")])]):_c('div',{staticClass:"filters filters__selected",on:{"click":function($event){$event.stopPropagation();_vm.dropdown = _vm.openDropdown('category')}}},[_c('p',{staticClass:"uk-text-truncate"},[_vm._v(_vm._s(_vm.categoryFilter.title))]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","fill":"none","stroke":"#262626","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('path',{attrs:{"d":"M6 9l6 6 6-6"}})])]),_c('div',{staticClass:"filters filters__types",class:{
          'filters__dropdown' : _vm.mobile,
          'active': _vm.dropdown === 'category'
        }},_vm._l((_vm.categories),function(item,index){return _c('button',{key:index,staticClass:"filters filters__type",class:{'filters__type-active' : item.id === _vm.categoryFilter.id},on:{"click":function($event){return _vm.changeFilter('category', item)}}},[_vm._v(" "+_vm._s(_vm.fixTitleCategory(item.title))+" ")])}),0)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }