<template>
  <div class="slider">
    <div class="slider__header">
      <div class="slider__category">
        <div class="slider__category-title">
          {{ category.title }}
        </div>
        <div class="slider__category-link">
          <a href="/catalog" class="category-link">
            <span>Все ароматы</span>

            <span class="icon-next">
              <div class="eik-i eik-i-arrow"></div>
            </span>
          </a>
          <div class="slider__navigation">
            <div class="navigation-pages">
              <span class="active">{{  thisPage }}</span>
              <span class="separator"></span>
              <span>{{ lastPage }}</span>
            </div>
            <div class="navigation-arrows">
              <div
                  class="navigation-arrow-left"
                  :class="{ 'navigation-arrow_disabled': thisPage == 1 }"
                  @click="showPrev"
              >
                <div class="eik-i eik-i-arrow eik-i-arrow-left"></div>
              </div>
              <div
                  class="navigation-arrow-right"
                  :class="{ 'navigation-arrow_disabled': thisPage == lastPage }"
                  @click="showNext"
              >
                <div class="eik-i eik-i-arrow eik-i-arrow-right"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
        class="slider__slides"
        :data-slider="slideIndex"
    >
      <slick-carousel
          v-if="slides"
          v-bind="settings"
          ref="carousel"
          @afterChange="afterChange"
      >
        <div
            class="slider__content"
            v-for="(slide, index) in slides"
            :key="index"
        >
          <div class="slider__slide">
            <div class="slider__block">
              <div class="slider__block-button">
                  {{ 'Виды упаковок данного аромата' }}
              </div>
              <div class="slider__block-header">
                <div class="slider__block-title">
                  {{slide.titleDefault}}
                </div>
                <div class="slider__block-more">
                  {{slide.titleRuDefault}}
                </div>
                <div
                    class="slider__block-description"
                    v-html="slide.descriptionDefault"
                >
                </div>
              </div>
              <div class="slider__block-products">
                <div
                    v-for="(product, pi) in slide.products"
                    class="slide__product"
                    :key="pi"
                >
                  <span>
                    <div class="slide__product-image">
                      <img
                          :alt="product.fileDefault"
                          :src="getImage('products', imagePath(product))"
                      />
                    </div>
                    <div class="slide__product-info">
                      <div class="infobox">
                        {{ product.package.title }}
                        <br>
                        {{ product.article }}
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <MarketplaceButton />
            </div>
            <div class="slider__image">
              <img
                  :alt="slide.file"
                  :src="getImage('slider', slide.file)"
              />
            </div>
          </div>
        </div>
      </slick-carousel>
    </div>
  </div>
</template>

<script>
import utils from '../utils'
import SlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import MarketplaceButton from './MarketplaceButton.vue'

export default {
  props: {
    item: null,
    slideIndex: null,
    isDevice: null,
  },
  components: {
    SlickCarousel,
    MarketplaceButton
  },
  data() {
    return {
      slides: this.item ? this.item.slides : null,
      category: this.item ? this.item.category : null,
      settings: {
        "dots": false,
        "arrows": false,
        "fade": false,
        "edgeFriction": 0.35,
        "infinite": false,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "lazyLoad": this.isDevice === 'desktop' ? "ondemand" : "false",
        "adaptiveHeight": true
      },
      wait: false,
      thisPage: 1,
      lastPage: this.item ? this.item.slides.length : 1,
    }
  },
  methods: {
    getImage(type, name) {
      const { getStaticImage } = utils()
      return getStaticImage(type, name)
    },
    imagePath(product) {
      return String(product.id).concat('/', product.fileDefault)
    },
    showNext() {
      if (!this.wait && this.thisPage !== this.lastPage) {
        this.wait = true;
        this.$refs.carousel.next()
        this.thisPage = this.thisPage + 1
        setTimeout(() => this.wait = false, this.settings.speed + 100);
      }
    },
    showPrev() {
      if (!this.wait && this.thisPage !== 1) {
        this.wait = true;
        this.$refs.carousel.prev()
        this.thisPage = this.thisPage - 1
        setTimeout(() => this.wait = false, this.settings.speed + 100);
      }
    },
    afterChange(page) {
      this.thisPage = page + 1
    },
  }
}
</script>