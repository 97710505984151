<template>
    <div class="marketplace-buttons">
        <button 
            class="uk-button uk-button-default marketplace-button" 
            @click="marketplaceSearch"
            uk-toggle="#modal"
        >
            Купить на маркетплейсе
        </button>
        <a class="marketplace-link" href="/map">Розничные магазины на карте</a>
    </div>
</template>

<script>
export default {
    props: {
        search: {
            default: null,
            type: String
        }
    },
    methods: {
        marketplaceSearch() {
            this.$store.commit('setProduct', this.search);
        }
    }
}
</script>