export default function () {
  const groupingAccesories = function(products) {
    let groupByPackage = groupArrayOfObjects(products, 'package')
    let accessories = []

    Object.entries(groupByPackage).forEach(item => {
      accessories.push({ package: item[0], rows: item[1] })
    })
    
    return accessories
  };

  const groupingProducts = function(products, isTablet) {
    let groupByPackage = groupArrayOfObjects(products, 'package')
    let groupByCategory = null
    let packages = []
    let result = []

    Object.entries(groupByPackage).forEach(item => {
      packages.push({ package: item[0], rows: item[1] })
    })

    packages.forEach(item => {
      groupByCategory = groupArrayOfObjects(item.rows, 'category')

      Object.entries(groupByCategory).forEach(category => {
        result.push({ package: item.package, category: category[0], rows: category[1] })
      })
    })
    
    if (!isTablet) return buildCatalog(result)
    return buildTabletCatalog(result)
  }

  const groupingNewestProducts = function(products, isTablet) {
    let groupByPackage = groupArrayOfObjects(products, 'package')
    let packages = []

    Object.entries(groupByPackage).forEach(item => {
        let categories = []
        item[1].forEach(el => {
            if (categories.indexOf(el.category) === -1) {
                categories.push(el.category);
            }
        });

        categories = categories.join( ' | ' )

      packages.push({ package: item[0], rows: item[1], category: categories })
    })
    
    if (!isTablet) return buildCatalog(packages)
    return buildTabletCatalog(packages)
  }
  
  const buildTabletCatalog = function(products) {
    products.forEach(block => {
      // const leftColumn = []
      block['leftColumn'] = []
      // if (block.rows.length > 4) {
        //const rows = Math.ceil(block.rows.length / 3)

      for (let i = 0; i < block.rows.length; i += 3) {
        const row = block.rows.slice(i, i + 3)

        for (let j = 0; j < row.length; j++) row[j]['large'] = false

        if (block['leftColumn'].length % 2 === 0) row[0]['large'] = true
        else row[ row.length - 1 ]['large'] = true

        block['leftColumn'].push(row)
      }
      // }
    })

    return products
  }

  const buildCatalog = function(products) {
    //let left = false
    products.forEach(block => {
      //left = !left
      let leftColumn = []
      let rightColumn = []
      const evenLength = block.rows.length % 2 === 0
      if (block.rows.length > 4) {
        let bigBlocks = Math.trunc(block.rows.length / 3)
        //if (bigBlocks >= 2) bigBlocks = bigBlocks + ( evenLength ? 0 : 1)

        let leftBB = ( evenLength ? bigBlocks / 2 : Math.trunc(bigBlocks / 2) ) // + ( (left && !evenLength) ? 1 : 0 )
        let rightBB = bigBlocks - leftBB

        const totalBlocks = block.rows.length - bigBlocks + (leftBB * 4) + (rightBB * 4)

        const productCount_L = (totalBlocks / 2) - leftBB * 4 + leftBB
        const productCount_R = (totalBlocks / 2) - rightBB * 4 + rightBB

        let delay = 0;
        for (let i = 0; i < block.rows.length; i++) {
          if (i < productCount_L) {

            if (leftBB === productCount_L) block.rows[i]['large'] = true
            else {
              if (leftBB > 0 && delay === 0) {
                block.rows[i]['large'] = true
                leftBB = leftBB - 1
                delay = 2
              }
              else {
                block.rows[i]['large'] = false
                delay -= 1
              }
            }

            leftColumn.push(block.rows[i])
            if (i + 1 >= productCount_L) delay = 0
          }
          else {

            if (rightBB === productCount_R) block.rows[i]['large'] = true
            else {
              if (rightBB > 0 && delay === 0) {
                block.rows[i]['large'] = true
                rightBB = rightBB - 1
                delay = 2
              }
              else {
                block.rows[i]['large'] = false
                delay -= 1
              }
            }
            
            rightColumn.push(block.rows[i])
          }
        }
      }
      else {
        for (let i = 0; i < block.rows.length; i++) {
          if (i % 2 === 0 || block.rows.length <= 2) leftColumn.push(block.rows[i])
          else rightColumn.push(block.rows[i])
        }
      }
      block['leftColumn'] = leftColumn
      block['rightColumn'] = rightColumn
    })
    
    return products
  };
  
  const groupArrayOfObjects = function(list, key) {
    return list.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  return {groupingAccesories, groupingProducts, groupingNewestProducts}
}