<template>
  <div class="store-map">

    <div class="switch-map" v-if="isDevice !== 'desktop'">
      <div class="switch-map__buttons">
        <div class="switch-map__button">
          <button
              class="map-point__button"
              :class="{'is-active': switchListMap === 'list'}"
              @click="switchListMap = 'list'"
          >
            Списком
          </button>
        </div>
        <div class="switch-map__button">
          <button
              class="map-point__button"
              :class="{'is-active': switchListMap === 'map'}"
              @click="switchListMap = 'map'"
          >
            На карте
          </button>
        </div>
      </div>
    </div>

    <div class="assurance-body">
      <div class="map-navigation">
        <div class="map-navigation__selection">

          <div class="selection-item">
            <input
                name="city"
                class="uk-input map-selector"
                placeholder="Выберите город"
                autocomplete="disabled"
                @keyup="insertedSearchFilter"
                @focus="showListCities = true"
                @blur="$event.target.nextSibling.matches(':hover') ? '' : showListCities = false"
                v-model="selectedCityName"
            />
            <div class="selection-item__list" :class="{'is-active' : showListCities}">
              <div v-for="(city, index) in initDataCities"
                   :key="index"
                   v-show="showBySearch(city)"
                   @click="onChangeCity(city)"
                   class="selection-item__list-item"
              >
                {{ city.name }}
              </div>
            </div>
          </div>
          <div class="selection-item__location" @click="getLocationByClientIP">
            <div class="eik-i eik-i-location" v-if="!loadLocation"></div>
            <div class="location__spin" uk-spinner v-else></div>
          </div>
        </div>

        <div class="map-list" v-if="isDevice === 'desktop' || switchListMap === 'list'">
          <div
              class="map-point"
              v-for="(point, index) in initDataPoints"
              :key="index"
          >
            <p class="map-point__type">{{ point.segment ? point.segment : 'Сеть магазинов' }}</p>
            <p class="map-point__title" v-if="point.name">{{ fixNamePoint(point) }}</p>

            <button
                class="map-point__button"
                :class="{'is-active': selectedPoint === point.id}"
                @click="(selectedPoint = point.id) && (initCoords = point.coords)"
            >
              На карте
            </button>

            <div class="map-point__row" >
              Телефон:
              <span v-if="point.phone && point.phone.length">
                <template v-for="(item, index) in point.phone">
                  <div :key="index">{{ item }}</div>
                </template>
              </span>
              <span v-else class="nf-point">не указан</span>
            </div>
            <div class="map-point__row">
              E-mail:
              <span v-if="point.email && point.email.length">
                <template v-for="(item, index) in point.email">
                  <div :key="index">{{ item }}</div>
                </template>
              </span>
              <span v-else class="nf-point">не указан</span>
            </div>
            <div class="map-point__row">
              Cайт:
              <span v-if="point.url && point.url.length">
              <template v-for="(item, index) in point.url">
                <a :href="item" target="_blank" :key="index">{{ item }}</a>
              </template>
            </span>
              <span v-else class="nf-point">не указан</span>
            </div>
            <p class="map-point__address" v-html="fixTransferText(point.address)"></p>
          </div>
        </div>
        <div class="map-message">
          <span @click="$emit('feedback')">Сообщите об ошибке</span>
        </div>
      </div>

      <div
          class="interactive-map"
          v-show="isDevice === 'desktop' || switchListMap === 'map'"
      >
        <yandex-map
            :settings="settings"
            :coords="initCoords"
            :controls="controls"
            :zoom="15"
            ref="ymap"
        >
          <ymap-marker
              v-for="(point, index) in initDataPoints"
              :key="index"
              :marker-id="index"
              :coords="point.coords"
              :hint-content="point.signboard"
              :icon="selectedPoint === point.id ? selectedMarkerIcon : markerIcon"
              :balloon-template="point.balloon"
          ></ymap-marker>
        </yandex-map>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import utils from '../utils';

export default {
  components: {
    yandexMap,
    ymapMarker
  },
  props: {
    isDevice: null,
  },
  data() {
    return {
      apiCities: 'cities/eikosha',
      apiPoints: 'points/eikosha',
      initDataCities: [],
      initDataPoints: [],
      initCoords: [55.4507, 37.3656],
      selectedCity: null,
      selectedSearch: [],
      selectedCityName: '',
      showListCities: false,
      selectedPoint: null,
      settings: {
        apiKey: process.env.VUE_APP_YANDEX_API_KEY,
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1',
      },
      markerIcon: {
        layout: 'default#image',
        imageHref: '/img/markerDefault.png',
        imageSize: [62, 56],
        imageOffset: [-31, -56],
      },
      selectedMarkerIcon: {
        layout: 'default#image',
        imageHref: '/img/markerSelected.png',
        imageSize: [62, 56],
        imageOffset: [-31, -56],
      },
      controls: ['zoomControl', 'typeSelector', 'fullscreenControl'],
      loadLocation: false,
      switchListMap: 'list',
    }
  },
  computed: {
    detectRobot() {
      // eslint-disable-next-line
      const robots = new RegExp([
        /bot/,/spider/,/crawl/,
        /APIs-Google/,/AdsBot/,/Googlebot/,/Googlebot-Mobile/,
        /mediapartners/,/Google Favicon/,/Googlebot-Image/,
        /FeedFetcher/,/Google-Read-Aloud/,
        /DuplexWeb-Google/,/googleweblight/,
        /bing/,/yandex/,/baidu/,/duckduck/,/yahoo/,
        /ecosia/,/ia_archiver/,
        /facebook/,/instagram/,/pinterest/,/reddit/,
        /slack/,/twitter/,/whatsapp/,/youtube/,
        /semrush/,
      ].map((r) => r.source).join("|"),"i");

      return robots.test(navigator.userAgent);
    }
  },
  methods: {
    setBlockTime: function () {
      let blockTime = new Date()
      blockTime.setDate(blockTime.getDate() + 4)
      localStorage.setItem('blockTime', +blockTime)
    },
    getInitDataCities: async function () {
      const {fetchData} = utils()
      let response = await fetchData('get', this.apiCities)
      this.initDataCities = response ? response : []

      const blockTime = localStorage.getItem('blockTime')

      if (blockTime !== null) {
        if (+new Date() >= parseInt(blockTime)) {
          localStorage.removeItem('blockTime')
          localStorage.removeItem('location')
        } else {

          const location = JSON.parse(localStorage.getItem('location'))
          this.setBlockTime()

          this.selectedCity = this.initDataCities.find(city => {
            return city.name === location.name
          })

          if (!this.selectedCity) return -1

          this.selectedCityName = this.selectedCity.name
          this.getInitDataPoints(this.selectedCity)
          return 1
        }
      }

      this.getLocationByClientIP()
      return 0
    },
    getInitDataPoints: async function (city) {
      const {fetchData} = utils()
      const targetGetPoints = `${this.apiPoints}/${city.id}`
      const response = await fetchData('get', targetGetPoints)
      if (!response) return -1

      let temp = response
      let lat = 0, lon = 0
      for (let i = 0; i < response.length; i += 1) {
        if (!temp[i].coords) {
          temp[i].coords = [
            temp[i].address.latitude,
            temp[i].address.longitude,
          ];
          const {icon} = temp[i]
          delete temp[i].icon
          temp[i].icon = {content: icon}
          temp[i].address = temp[i].address.name

          lat = lat + temp[i].coords[0] / response.length
          lon = lon + temp[i].coords[1] / response.length
        }
      }
      this.initDataPoints = temp
      this.initCoords = [lat, lon]
    },
    getLocationByClientIP: async function () {
      let IP = null
      if (this.loadLocation) return -1

      this.loadLocation = true
      const targetGetIP = process.env.VUE_APP_CLIENT_IP

      if (this.detectRobot === false) {
        await axios
            .get(targetGetIP)
            .then((res) => IP = res.data);
      }

      if (IP) {
        const targetGetLocal = `${process.env.VUE_APP_GEO_URL}/${process.env.VUE_APP_GEU_KEY}/json/${IP}`
        let response = null
        await axios
            .get(targetGetLocal)
            .then((res) => response = res.data);

        this.loadLocation = false
        if (!response) return -1

        let location = {
          lat: response.city.lat,
          lon: response.city.lon,
          name: response.city.name_ru
        }

        this.selectedCity = this.initDataCities.find(city => {
          return city.name === location.name
        })

        this.getInitDataPoints(this.selectedCity)
        this.selectedCityName = this.selectedCity?.name
        localStorage.setItem('location', JSON.stringify(location))
        this.setBlockTime()
      }
      this.loadLocation = false
    },
    showBySearch(value) {
      if (!this.selectedSearch || this.selectedSearch.length < 2) return true;
      return (value.name && value.name.toLowerCase().includes(this.selectedSearch.toLowerCase()));
    },
    onChangeCity(city) {
      this.showListCities = false
      this.selectedCity = city
      this.selectedCityName = city.name
      this.switchListMap = 'list'
      this.getInitDataPoints(city)
    },
    insertedSearchFilter(event) {
      this.selectedSearch = event.target.value
      this.$forceUpdate();
    },
    fixTransferText(str) {
      const pos = str.lastIndexOf(',') + 2
      return str.substr(0, pos) + '<nobr>' + str.substr(pos) + '</nobr>';
    },
    fixNamePoint(point) {
      let except = ['автомагазин', 'автомойка', 'магазин ']
      return except.includes(point.name.toLowerCase()) ? point.signboard : point.name
    }
  },
  watch: {
    selectedPoint(value) {
      if (value && this.isDevice !== 'desktop') {
        this.switchListMap = 'map'
      }
    }
  },
  mounted() {
    this.getInitDataCities()
  }
}
</script>