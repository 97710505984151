import Vue from 'vue'
import Vuex from 'vuex';

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        product: null,
        modal: { name: null },
        filters: {
            packageTab: 'packageType',
            package: { id: 0, title: 'Все'},
            packageType: { id: 0, title: 'Все'},
            category: { id: 0, title: 'Все'},
        },
    },
    getters: {
        getProduct: (state) => state.product,
        getModal: (state) => state.modal,
        getFilters(state) {
            return state.filters
        }
    },
    mutations: {
        setProduct(state, payload) {
            state.product = payload;
        },
        setModal(state, payload) {
            state.modal.name = payload;
        },
        changeFilter (state, payload) {
            let newFilters = {
                packageTab: state.filters.packageTab,
                package: state.filters.package,
                packageType: state.filters.packageType,
                category: state.filters.category,
            }

            if (payload.filter === 'category') newFilters.category = payload.content
            else if (payload.filter === 'tab') newFilters.packageTab = payload.content 
            else if (payload.filter === 'package' ) {
                newFilters.package = payload.content
                newFilters.packageTab = payload.filter
            }
            else if (payload.filter === 'type') {
                newFilters.packageType = payload.content
                newFilters.packageTab = payload.filter
            }

            state.filters = newFilters
        },
    },
});

export default store

