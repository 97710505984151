<template>
  <div class="catalog page">
    <div class="section section__header">
      <div
          class="section__background blur"
          :class="{'section_anim' : backgroundAnim }"
          v-rellax="{speed: -3 }">
        <img
            v-if="backgroundNext"
            :src="require(`@/assets/img/backgrounds/${backgroundNext.file}`)"
            alt="img"
        />
        <img
            v-if="background"
            :src="require(`@/assets/img/backgrounds/${background.file}`)"
            alt="img"
        />
      </div>
      <div class="section__content uk-container uk-container-large">
        <div class="section__navigation section__navigation_light">
          <p><span><a href="/">Главная</a> |</span> Каталог</p>
        </div>

        <catalog-filters
            :black="false"
            :header="false"
        />
      </div>
      <div
          v-if="background"
          class="section__place"
          :class="{
            section__place_dark: background.theme == 'dark',
            section_anim : backgroundAnim
          }"
      >
        <span v-html="background.title"></span>
      </div>
    </div>

    <div class="section section__newest" v-if="newProducts.length && loadingItemCount >= 3">
        <div class="uk-container uk-container-large">
            <div class="container_new" v-if="(products.length > 0 || accessories.length > 0)">
                <div
                    class="container__block"
                    v-for="(block, index) in newProducts"
                    :key="index"
                >
                  <div class="container__header_newest">
                      {{block.package + ` | ` + block.category}}
                    <div class="newest"> Новинка</div>
                  </div>
                  <div class="container__columns" v-if="!isTablet">
                      <div class="container__column">
                          <CatalogItem
                              v-for="(item, index) in block.leftColumn"
                              :key="index"
                              :item="item"
                              :large="item.large"
                              :active="selectedProduct === item.id + '-prod'"
                              @clickOutside="clickOutside"
                              @selectProduct="selectedProduct = item.id + '-prod'"
                              @openModal="openModal"
                          />
                      </div>
                      <div class="container__column">
                          <CatalogItem
                              v-for="(item, index) in block.rightColumn"
                              :key="index"
                              :item="item"
                              :large="item.large"
                              :active="selectedProduct === item.id + '-prod'"
                              @clickOutside="clickOutside"
                              @selectProduct="selectedProduct = item.id + '-prod'"
                              @openModal="openModal"
                          />
                      </div>
                  </div>
                  <div class="container__columns" v-else>
                      <div class="container__row" v-for="(row, index) in block.leftColumn" :key="index">
                          <CatalogItem
                              v-for="(item, index) in row"
                              :key="index"
                              :item="item"
                              :large="item.large"
                              :active="selectedProduct === item.id + '-prod'"
                              @clickOutside="clickOutside"
                              @selectProduct="selectedProduct = item.id + '-prod'"
                              @openModal="openModal"
                          />
                      </div>
                  </div>
                </div>
              </div>
   
        </div>
    </div>

    <div class="section section__main" ref="content">
      <div class="uk-container uk-container-large">
      <div class="container__catalog">
        <div class="package-filter" v-if="packageFilter && packageFilter.id > 0">
          <div class="package-filter__items">
            Вид упаковки:
            <span class="filter-tag" @click="packageFilter = {id: 0, title: 'Все'}">
              {{ packageFilter.title }}
              <div class="eik-i eik-i-close"></div>
            </span>
          </div>
        </div>

        <template v-if="loadingItemCount >= 3">
          <div v-if="(products.length > 0 || accessories.length > 0)">
            <div
                class="container__block"
                v-for="(block, index) in products"
                :key="index"
                id="`/catalog?package=${pack.id}`"
            >
              <div class="container__header">
                {{block.package + ` | ` + block.category}}
              </div>
              <div class="container__columns" v-if="!isTablet">
                <div class="container__column">
                  <CatalogItem
                      v-for="(item, index) in block.leftColumn"
                      :key="index"
                      :item="item"
                      :large="item.large"
                      :active="selectedProduct === item.id + '-prod'"
                      @clickOutside="clickOutside"
                      @selectProduct="selectedProduct = item.id + '-prod'"
                      @openModal="openModal"
                  />
                </div>
                <div class="container__column">
                  <CatalogItem
                      v-for="(item, index) in block.rightColumn"
                      :key="index"
                      :item="item"
                      :large="item.large"
                      :active="selectedProduct === item.id + '-prod'"
                      @clickOutside="clickOutside"
                      @selectProduct="selectedProduct = item.id + '-prod'"
                      @openModal="openModal"
                  />
                </div>
              </div>
              <div class="container__columns" v-else>
                <div class="container__row" v-for="(row, index) in block.leftColumn" :key="index">
                  <CatalogItem
                      v-for="(item, index) in row"
                      :key="index"
                      :item="item"
                      :large="item.large"
                      :active="selectedProduct === item.id + '-prod'"
                      @clickOutside="clickOutside"
                      @selectProduct="selectedProduct = item.id + '-prod'"
                      @openModal="openModal"
                  />
                </div>
              </div>
            </div>
            <div
                class="container__block"
                v-for="(block, index) in accessories"
                :key="`acc`+index"
            >
              <div class="container__header">
                {{`Аксессуары` + ( block.package != "null" ? ` для ` + block.package : ``)}}
              </div>
              <div class="container__columns">
                <CatalogItem
                    v-for="(item, index) in block.rows"
                    :key="index"
                    :item="item"
                    :accessories="true"
                    :active="selectedProduct === item.id + '-acc'"
                    @clickOutside="clickOutside"
                    @selectProduct="selectedProduct = item.id + '-acc'"
                    @openModal="openModal"
                />
              </div>
            </div>
          </div>
          <div v-else>
            По данному фильтру товар отсутствует <br>
            Воспользуйтесь фильтром
            <span class="link" @click="changeFilters()">Универсальные | Успокаивающие ароматы</span>
          </div>
        </template>
        <template v-else>
          <div class="loader loader-catalog-list">
            <div class="box box-flex" style="margin-bottom: 50px">
              <div class="item load-skeleton" style="height: 60px"></div>
              <div class="item item-flex" style="height: 874px">
                <div class="item item-full item-col-2 load-skeleton"></div>
                <div
                    class="item item-flex item-flex-inner item-col-2"
                    v-if="isDevice !== 'mobile'"
                >
                  <div class="item item-full item-col-2 item-bg-margin load-skeleton"></div>
                  <div class="item item-full item-col-2 item-bg-margin load-skeleton"></div>
                  <div class="item item-full item-col-2 item-bg-margin load-skeleton"></div>
                  <div class="item item-full item-col-2 item-bg-margin load-skeleton"></div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

    </div>
    </div>
    <CatalogModal
        :modalItem="modalItem"
        @closeModal="modalItem = null"
    />

    <MouseAnim
        v-if="isDevice == 'desktop'"
        :theme="backgroundTheme ? backgroundTheme : ''"
    />
  </div>
</template>

<script>
import BackgroundsJson from '@/assets/data/backgrounds.json'
import CatalogFilters from '../components/CatalogFilters'
import CatalogItem from '../components/CatalogItem'
import CatalogModal from '../components/CatalogModal'
import CatalogBuilder from '../catalog-builder'
import MouseAnim from '../components/MouseAnim'
import EventBus from '../event-bus'
import { mapState } from 'vuex'
import utils from '../utils'

export default {
  name: 'catalog-page',
  components: {
    CatalogFilters,
    CatalogItem,
    CatalogModal,
    MouseAnim
  },
  props: {
    isTablet: {
      type: Boolean,
    },
    isDevice: null,
  },
  data() {
    return {
      packageTab: 'type',
      packageFilter: { 'id': 0, 'title': 'Все'},
      packageTypeFilter: { 'id': 0, 'title': 'Все'},
      categoryFilter: { 'id': 0, 'title': 'Все'},
      products: [],
      newProducts: [],
      accessories: [],
      loadingItemCount: 0,
      selectedProduct: null,
      modalItem: null,
      backgroundInterval: 4000,
      background: null,
      backgroundNext: null,
      backgroundAnim: false,
      backgroundTheme: null,
      lastRandIndex: -1,
    }
  },
  watch: {
    packageTypeFilter() {
      this.fetchData()
    },
    packageFilter(value) {
        if (value && value.id == 0) {
            this.fetchData()
        }  
    },
    categoryFilter() {
      this.fetchData()
    },
    isTablet() {
      this.products = []
      this.fetchData()
      this.getProducts(true)
    },
    filters (newValue) {
      if (newValue.packageTab === 'type') {
        this.packageTab = 'type'
        if (this.packageTypeFilter.id != newValue.packageType.id) {
          this.packageTypeFilter = newValue.packageType
        }
      }
      if (this.categoryFilter.id != newValue.category.id) {
        this.categoryFilter = newValue.category
      }
    },
  },
  computed: {
    backgrounds() {
      return BackgroundsJson.filter(item => {
        return item.route === this.$route.path
      })
    },
    ...mapState(['filters'])
  },
  methods: {
    openModal(value) {
      this.modalItem = value
    },
    // changeParams(value) {
    //   if (value.key === 'category') { this.categoryFilter = value.item }
    //   if (value.key === 'type') { this.packageTab = 'type'; this.packageTypeFilter = value.item }
    //   if (value.key === 'package') { this.packageTab = 'package'; this.packageFilter = value.item }
    // },
    changeFilters() {
      this.packageFilter = {id: 0, title: 'Все'}
      EventBus.$emit('defaultFilters')
    },
    clickOutside() {
      this.selectedProduct = null
    },
    getImage(type, name) {
      const { getStaticImage } = utils()
      return getStaticImage(type, name)
    },
    fetchData() {

      const all = (
        (this.packageFilter.id === 0 && this.packageTab === 'package')
        || 
        (this.packageTypeFilter.id === 0 && this.packageTab === 'type')
      )
      
      const accessories = (
        (this.packageFilter.id === -1 && this.packageTab === 'package')
        || 
        (this.packageTypeFilter.id === -1 && this.packageTab === 'type')
      )

      if (all) {
        this.loadingItemCount = 0
        this.getAccessories()
        this.getProducts()
        this.getProducts(true)
        return 1
      }

      if (accessories) {
        this.loadingItemCount = 2
        this.getAccessories()
        this.products = []
        this.newProducts = []
      }
      else {
        this.loadingItemCount = 1
        this.getProducts()
        this.getProducts(true)
        this.accessories = []
      }
    },
    getProducts: async function(newest = false) {
      const { groupingProducts, groupingNewestProducts } = CatalogBuilder()

      this.products = []
      this.newProducts = []

      const { fetchData } = utils()
      let target = ['composition=true']
      let api = 'products'

      if (this.categoryFilter.id > 0) {
        target.push(`category=${this.categoryFilter.id}`)
      }

      if (this.packageFilter.id > 0) {
        target.push(`package=${this.packageFilter.id}`)
      }

      if (this.packageTypeFilter.id > 0) {
        target.push(`packageType=${this.packageTypeFilter.id}`)
      }

      if (newest) {
        target.push(`newest=true`)
      }

      api = api.concat(`?${target.join('&')}`)

      let response = await fetchData('get', api)
      if (response) {
        (
            newest
            ?
            this.newProducts = groupingNewestProducts(response.data, this.isTablet)
            :
            this.products = groupingProducts(response.data, this.isTablet)
        )
        this.loadingItemCount++
        this.packageFilter = response?.package ?? this.packageFilter
      }
    },
    getAccessories: async function() {
      const { groupingAccesories } = CatalogBuilder()

      this.loading = true
      this.accessories = []
      
      const { fetchData } = utils()
      let api = 'accessories?optimize=true'

      if (this.packageFilter.id > 0) {
        api = api.concat(`&package=${this.packageFilter.id}`)
      }

      let response = await fetchData('get', api)
      if (response) {
        this.accessories = groupingAccesories(response.data)
        this.loadingItemCount++
      }
    },
    updateBackground() {
      if (this.backgrounds) {
        let randIndex = null

        do {
          randIndex = Math.floor(Math.random() * this.backgrounds.length)
        } while(this.lastRandIndex === randIndex)

        this.lastRandIndex = randIndex
        return this.backgrounds[randIndex]
      }
      return null
    },
    dynamicBackgrounds() {
      this.background = this.backgroundNext;
      this.backgroundAnim = true
      const delay = 1500

      setTimeout(() => {
        this.backgroundNext = this.updateBackground()
      }, delay)

      setTimeout(() => {
        this.backgroundAnim = false
        this.backgroundTheme = this.backgroundNext.theme
        this.$emit('updateHeaderTheme', {theme: this.backgroundNext.header})
      }, this.backgroundInterval)

      setTimeout(() => {
        this.dynamicBackgrounds()
      }, this.backgroundInterval + delay)
    }
  },
  mounted() {
    this.packageFilter = {id: this.$route.query.package}
    
    this.getProducts()
    this.getProducts(true)
    this.getAccessories()

    if (this.$route.query.package) {
      this.$nextTick(() => {
        const scroll = this.$refs.content.offsetTop;
        window.scrollTo({
            top: scroll - 100,
            behavior: "smooth"
        });
      })  
    }

    if (this.backgrounds.length) {
      if (this.backgrounds.length > 1) {
        this.backgroundNext = this.updateBackground()
        this.backgroundTheme = this.backgroundNext.theme
        this.$emit('updateHeaderTheme', {theme: this.backgroundNext.header})

        this.dynamicBackgrounds()
        return 1
      }
      this.background = this.backgrounds[0]
      this.backgroundAnim = true
      this.backgroundTheme = this.backgroundNext.theme
      this.$emit('updateHeaderTheme', {theme: this.backgroundNext.header})
    }
  },
  metaInfo: {
      title: 'Каталог',
      titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
}
</script>