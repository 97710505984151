<template>
  <div
      class="mouse"
      :class="{ hide: isInitHide, mouse_dark : theme === 'dark' }"
      v-scroll="handleScroll"
    >
    <div class="scroll">
      <div class="scroll-mouse"></div>
      <div class="scroll-text"><span>Листайте <span class="sc-dir">вниз</span></span></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    theme: null
  },
  data() {
    return {
      isInitHide: true,
    }
  },
  methods: {
    handleScroll: function () {
      this.isInitHide = window.scrollY > 50;
    },
  },
  directives: {
    scroll: {
      inserted: function (el, binding) {
        let f = function (evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener('scroll', f)
          }
        }
        window.addEventListener('scroll', f)
      }
    },
  },
  mounted() {
    setTimeout(() => this.handleScroll(), 2000)
  }
}
</script>

<style lang="sass">
  @import "@/assets/sass/mouse.scss"
</style>