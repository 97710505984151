<template>
    <div class="products_page">
        <div class="section section__main"> 
            <div class="uk-container uk-container-large">
                <div class="section__navigation">
                        <p><span><a href="/">Главная</a> |</span> О продукции</p>
                </div>
                <div class="products_main_block">
                    <div class="products_block">
                        <div class="products_block_left">
                            <div class="products_block_left_item" v-for="(article, index) in leftColumn" :key="index">
                                <a class="products-link" :href="article.link">{{ article.title.toUpperCase() }}</a>
                            </div>
                            
                        </div>
                        <ul uk-accordion class="products_block_right">
                            <li class="" v-for="(article, index) in rightColumn" :key="index">
                                <a class="uk-accordion-title" href="#">{{ article.title.toUpperCase() }}</a>
                                <div class="uk-accordion-content" v-html="article.description"></div>
                            </li>
                        </ul>
                    </div>
                </div>     
            </div>
        </div> 
    </div>   
</template>

<script>

import utils from '../utils';

export default {
  props: {
    isDevice: null,
  },
  data() {
    return {
        leftColumn: [],
        rightColumn: []
    };
  },
  methods: {
    openPage(link) {
        this.$root.$emit('openPage', link);
    },
    fetchData: async function(action, target) {
        const { fetchData } = utils();
        let response = await fetchData(action, target);
        if (response) {
            this.leftColumn = response.data.filter(article => article.link.length > 0)
            this.rightColumn = response.data.filter(article => article.link.length === 0)
        }
    },
    resizeHeaderTheme() {
      if (this.isDevice === 'desktop') {
        this.$emit('updateHeaderTheme', {theme: 'light'})
      }
    },
  },
  watch: {
    isDevice() {
      this.resizeHeaderTheme()
    },
  },
//   async beforeMount() {
//     const response = this.fetchData('get', 'aboutproducts');
//     if (response) {
//         console.log(response)
//       const about_products = response ? response.data : null;
//       this.blockLeft = about_products.slice(0, about_products.length / 2);
      
//     }
//   },
  metaInfo: {
    title: 'О продукции',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
  mounted() {
    this.resizeHeaderTheme()
    this.fetchData('get', 'aboutproducts')
    //   const about_products = response ? response.data : null;
    //   this.blockLeft = about_products.slice(0, about_products.length / 2);
  }
};
</script>