<template>
    <div id="modal" class="uk-flex-top eik-modal" uk-modal>
        <div class="modal-content uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <div class="feedback__close uk-modal-close-default" uk-toggle="target: #modal">
                <div class="eik-i eik-i-close"></div>
            </div>
            <slot>
    
            </slot>
        </div>
    </div>
</template>
  
<script>
import UIkit from 'uikit'

export default {
    mounted() {
        UIkit.util.on('#modal', 'hidden', () => {
            this.$store.commit('setProduct', null);
        })
    }
}
</script>