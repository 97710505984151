<template>
  <div id="app">
    <template v-if="isDevice">
      <Header
          class="header__fixed"
          :isMobile="isMobile"
          :isDevice="isDevice"
          :headerTheme="headerTheme"
      />
      <Router-view
          :isDevice="isDevice"
          :isTablet="isTablet"
          @feedback="enabled = $event.enabled"
          @updateHeaderTheme = "headerTheme = $event.theme"
      />
      <Footer
          :isMobile="isMobile"
          @feedback="enabled = $event.enabled"
      />
      <FeedbackForm
          :isMobile="isMobile"
          :enabled="enabled"
          @getOpenStatus="enabled = $event.enabled"
      />
      <Modal>
        <MarketplaceForm />
      </Modal>
    </template>
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import FeedbackForm from "./components/FeedbackForm";
import Modal from "./components/Modal";
import MarketplaceForm from "./components/MarketplaceForm"

export default {
  name: 'App',
  components: {
    FeedbackForm,
    Header,
    Footer,
    Modal,
    MarketplaceForm
  },
  data() {
    return {
      enabled: false,
      mobileWidth: 540,
      tabletWidth: 1024,
      windowWidth: window.innerWidth,
      headerTheme: '',
    }
  },
  computed: {
    isMobile() {
      return this.windowWidth <= this.mobileWidth
    },
    isTablet() {
      return this.windowWidth <= this.tabletWidth && this.windowWidth > this.mobileWidth
    },
    isDevice() {
     if (this.isMobile) return 'mobile'
     if (this.isTablet) return 'tablet'
     return 'desktop'
    }
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    if ((this.isTablet || this.isMobile) && this.$route.name === 'aboutproducts') this.headerTheme = 'light'
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
}
</script>
