<template>
  <div
    class="container__item"
    :class="{
      'medium': accessories,
      'large': large || popup,
      'active': active,
    }"
    @click.stop="$emit('selectProduct')"
    v-clickoutside="clickOutside"
  >
    <div class="item__header">
      <div class="item__image">
        <div class="uk-position-relative uk-visible-toggle uk-dark" tabindex="1" uk-slideshow>
          <ul class="uk-slideshow-items" v-if="!optimize && item.files">
            <li v-for="(file, index) in item.files" :key="index" >
              <img
                  :src="getImage( 'products',  `${item.id}/${file}`, accessories, 'accessories')"
                  :alt="file"
              />
            </li>
          </ul>
          <ul class="uk-slideshow-items" v-else>
            <li>
              <img
                  :src="getImage( 'products',  `${item.id}/${item.fileDefault}`, accessories, 'accessories')"
                  :alt="item.fileDefault"
              />
            </li>
          </ul>
          <span
              class="uk-position-center-left uk-position-small uk-hidden-hover"
              uk-slidenav-previous
              uk-slideshow-item="previous"
          >
          </span>
          <span
              class="uk-position-center-right uk-position-small uk-hidden-hover"
              uk-slidenav-next
              uk-slideshow-item="next"
          >
          </span>
        </div>
      </div>
      <div class="item__buttons">
        <MarketplaceButton :search="item.article"/>
      </div>
      <div class="item__title">
        <p>{{item.title}}</p>
        <p>{{item.titleRu}}</p>
      </div>
    </div>

    <div v-if="accessories" class="item__description">
      Для мелового ароматизатора <br>
      {{item.package ? item.package: ''}}
    </div>
    <div v-else class="item__description" v-html="item.description"/>

    <div class="item__footer">
      <div v-if="!accessories" class="item__compositions">
        <img
          v-for="(comp, index) in item.composition"
          :key="index"
          :title="comp.title"
          :src="getImage('composition',  `${comp.file}`)"
          :alt="comp.file"
        />
      </div>
      <div class="item__article">
        Артикул: {{item.article}}
      </div>
    </div>

    <div v-if="active" class="item__footer-btn">
      <MarketplaceButton />
    </div>

  </div>
</template>

<script>
import utils from '../utils'
import MarketplaceButton from './MarketplaceButton.vue';

export default {
  name: 'catalog-item',
  components: {
    MarketplaceButton
  },
  props: {
    item: {
      type: Object
    },
    accessories: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    popup: {
      type: Boolean,
      default: false
    },
    optimize: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedProduct: null,
    }
  },
  methods: {
    getImage(type, name, state = false, type2 = null) {
      const { getStaticImage } = utils()
      return state ? getStaticImage(type2, name) : getStaticImage(type, name)
    },
    clickOutside() {
      if (!this.popup) this.$emit('clickOutside', { item: this.item });
    },
  },
}
</script>