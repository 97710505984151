<template>
  <div class="map page">
    <div class="section section__header_min">
      <div class="section__background section_anim" v-rellax="{speed: -3 }">
        <img src="@/assets/img/backgrounds/back-storemap-1.png" alt="img" />
      </div>

      <div class="section__content uk-container uk-container-large">
        <div class="section__navigation section__navigation_light" style="position: relative">
          <p><span><a href="/">Главная</a> |</span> Где купить</p>
        </div>

        <div class="section__banner">
          <p>Приобрести продукцию {{ appName }} можно онлайн или найти магазин в своем городе</p>
          <button class="uk-button uk-button-default pulse" uk-toggle="target: #modal">{{ 'Купить на маркетплейсе' }}</button>
        </div>

      </div>
      <div class="background-blue-transparent"></div>
    </div>


    <StoreMap
        :isDevice="isDevice"
        @feedback="$emit('feedback', { enabled: true })"
    />

  </div>
</template>

<script>
import StoreMap from '../components/StoreMap'
export default {
  components: {
    StoreMap,
  },
  props: {
    isDevice: null,
  },
  computed: {
    appName() {
      return process.env.VUE_APP_NAME
    }
  },
  methods: {
    resizeHeaderTheme() {
      if (this.isDevice === 'desktop') {
        this.$emit('updateHeaderTheme', {theme: 'dark'})
      }
    },
  },
  watch: {
    isDevice() {
     this.resizeHeaderTheme()
    }
  },
  mounted() {
    this.resizeHeaderTheme()
  },
  metaInfo: {
    title: 'Где купить',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
}
</script>