<template>
    <div class="marketplace">
        <div class="marketplace-header">Выберите маркетплейс</div>
        <div class="marketplace-list">
            <div class="marketplace-item">
                <a 
                    :href="buildQuery(marketplaces['ozon'])"
                    :class="[`button-marketplace`, `button-ozon`]"
                    target="_blank"
                    aria-label="ozon"
                >
                    <img src="@/assets/img/svg/ozon.svg" alt="ozon"/>
                </a>
            </div>
            <div class="marketplace-item">
                <a 
                    :href="buildQuery(marketplaces['wildberries'])"
                    :class="[`button-marketplace`, `button-wildberries`]"
                    target="_blank"
                    aria-label="wildberries"
                >
                    <img src="@/assets/img/svg/wildberries.svg" alt="wildberries"/>
                </a>
            </div>
        </div>
        <div class="marketplace-list">
            <div class="marketplace-item">
                <a 
                    :href="buildQuery(marketplaces['yandex'])"
                    :class="[`button-marketplace`, `button-yandex`]"
                    target="_blank"
                    aria-label="yandex"
                >
                    <img src="@/assets/img/svg/yandex.svg" alt="yandex"/> 
                </a>
            </div>
            <div class="marketplace-item">
                <a 
                    :href="buildQuery(marketplaces['sber'])"
                    :class="[`button-marketplace`, `button-sber`]"
                    target="_blank"
                    aria-label="sber"
                >
                    <img src="@/assets/img/svg/sber.svg" alt="sber"/> 
                </a>
            </div>
        </div>
        <div class="marketplace-footer">
            <a href="/map">Розничные магазины на карте</a>
        </div>
    </div>
</template>

<script>
import marketplaceMixin from "../marketplaceMixin"

export default {
    mixins: [marketplaceMixin],
}
</script>