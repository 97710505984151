import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from './views/Main.vue';
import Catalog from './views/Catalog.vue';
import AboutCompany from './views/AboutCompany.vue';
import OurPartners from './views/OurPartners.vue';
import StoreMap from './views/StoreMap.vue';
import SearchPage from './views/SearchPage.vue';
import NotFound from './views/NotFound.vue';
import AboutProducts from './views/AboutProducts.vue';
import AboutProductsSmell from './views/AboutProductsSmell.vue';
import AboutProductsOriginal from './views/AboutProductsOriginal.vue';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'main',
        component: Main
    },
    {
        path: '/catalog',
        name: 'catalog',
        component: Catalog
    },
    {
        path: '/company',
        name: 'company',
        component: AboutCompany
    },
    {
        path: '/partners',
        name: 'partners',
        component: OurPartners
    },
    {
        path: '/map',
        name: 'map',
        component: StoreMap
    },
    {
        path: '/search',
        name: 'search',
        component: SearchPage
    },
    {
        path: '/about/products',
        name: 'aboutproducts',
        component: AboutProducts
    },
    {
        path: '/about/products/smell',
        name: 'aboutproductssmell',
        component: AboutProductsSmell
    },
    {
        path: '/about/products/original',
        name: 'aboutproductsoriginal',
        component: AboutProductsOriginal
    },
    {
        path: '*',
        name: 'not-found',
        component: NotFound
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    /* eslint-disable */
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
    /* eslint-disable */
})

export default router
