<template>
  <div class="not-found page">
    <div class="section">
      <div class="section__content uk-container uk-container-large">
        <div class="section__navigation" style="position: relative">
          <p><span><a href="/">Главная</a> |</span> Страница не найдена</p>
        </div>
      </div>
    </div>

    <div class="section section__banner section__banner_min">
      <div class="banner__background">
        <div class="uk-container uk-container-large">
          <p>Приобрести продукцию {{ appName }} можно онлайн или найти магазин в своем городе</p>
          <button class="uk-button uk-button-default pulse" uk-toggle="target: #modal">{{ 'Купить на маркетплейсе' }}</button>
        </div>
      </div>
    </div>

    <div class="section section__not-found">
      <div class="uk-container uk-container-large">
        <div class="not-found-block">
          <div class="not-found-numb">404</div>
          <span>Страница не найдена</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    appName() {
      return process.env.VUE_APP_NAME
    }
  },
  props: {
    isDevice: null,
  },
  methods: {
    resizeHeaderTheme() {
      if (this.isDevice === 'desktop') {
        this.$emit('updateHeaderTheme', {theme: 'white'})
      }
    },
  },
  watch: {
    isDevice() {
      this.resizeHeaderTheme()
    }
  },
  mounted() {
    this.resizeHeaderTheme()
  },
  metaInfo: {
    title: 'О компании',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
}
</script>