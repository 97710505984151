<template>
  <div class="header"
       :class="{
          'header_focus': sfocus,
          'header_sticky' : headerSticky,
          'header_light': headerTheme === 'light',
          'header_whiteBckg': headerTheme === 'white'
        }"
       v-scroll="handleScroll"
  >
    <div class="uk-container uk-container-large">
      <div class="header__container">
        <div class="logo header__logo">
          <div class="logo__image">
            <a href="/">
              <img
                v-if="headerSticky || headerTheme === 'light' || headerTheme === 'white'"
                :src="require(isDevice === 'desktop' ? `@/assets/img/logo_full_dark.svg` : `@/assets/img/logo_sm_dark.svg`)"
                alt="eikosha"
              />
              <img
                v-else
                :src="require(isDevice === 'desktop' ? `@/assets/img/logo_full_light.svg` : `@/assets/img/logo.svg`)"
                alt="eikosha"
              />
            </a>
          </div>
        </div>
        <div class="logo__title">
          {{ 'Автомобильный парфюм из Японии' }}
        </div>

        <div class="header__group">
          <div class="header-menu">
            <div class="header-menu__container">
              <div
                  v-for="(item, i) in menu"
                  :key="i"
                  class="header-menu__item">
                <a
                  :href="item.href"
                  :class="{ 'active' : !$route.path.indexOf(item.href) }"
                >
                  {{ item.title }}
                </a>
              </div>
            </div>
          </div>

          <div class="header-button">
            <button
                class="uk-button uk-button-default pulse"
                uk-toggle="target: #modal"
            >
              {{ 'Купить на маркетплейсе' }}
            </button>
          </div>

          <div class="header-search">
            <div class="header-search__button">
              <button
                  @click="search($event)"
                  class="btn-search"
                  aria-label="Поиск"
              >
                <div class="eik-i eik-i-search"></div>
              </button>
              <input
                  type="text"
                  ref="input-search"
                  class="input-search"
                  placeholder="Поиск на сайте"
                  v-model="textSearch"
                  @focus="sfocus = true"
                  @blur="sfocus = false"
                  @keypress.enter="search($event)"
              />
            </div>
          </div>
          <div class="header-menu__btn">
            <div class="header-search__button">
              <button
                  type="button"
                  uk-toggle="target: #offcanvas-overlay"
                  class="btn-menu"
                  aria-label="Открыть меню"
              >
                <div class="eik-i eik-i-menu"></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="uk-container uk-container-large padding-unset"
      v-if="$route.path.indexOf('catalog') > 0"
    >
      <div 
        class="header__container"
        v-show="showFilters"
      >
        <catalog-filters
            :mobile="isMobile"
            :device="isDevice"
            :header="true"
            :black="true"
        />
      </div>
    </div>
    <div class="uk-container uk-container-large">
      <div class="header__container">
        <div class="container__menu">
        </div>
      </div>
    </div>
    <div id="offcanvas-overlay" uk-offcanvas="overlay: true; mode: none">
      <HeaderMenu/>
    </div>
  </div>
</template>

<script>
import MenuJson from '@/assets/data/menu.json';
import CatalogFilters from './CatalogFilters'
import HeaderMenu from './HeaderMenu.vue'

export default {
  name: 'header-component',
  components: {
    CatalogFilters,
    HeaderMenu
  },
  props: {
    isMobile: null,
    isDevice: null,
    headerTheme: null,
  },
  data() {
    return {
      menu: MenuJson,
      headerSticky: false,
      showFilters: false,
      sfocus: false,
      mobileMenu: false,
      textSearch: this.$route.query.text ?? '',
    }
  },
  directives: {
    scroll: {
      inserted: function (el, binding) {
        let func = function (evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener('scroll', func)
          }
        }
        window.addEventListener('scroll', func)
      }
    },
  },
  watch: {
    $route: {
      handler() {
        this.$refs['input-search']?.blur()
        this.textSearch = this.$route.query.text ?? ''
      },
      deep: true
    },
    sfocus() {
      if (!this.sfocus) {
        setTimeout(() => {
          this.textSearch = this.$route.query.text ?? ''
        }, 200)
      }
    }
  },
  methods: {
    search() {
      let input = this.$refs['input-search']

      if (input) {
        input.focus()
        if (input.value.length > 2 && this.$route.query.text !== input.value) {
          this.$router.push({name: 'search', query: {text: input.value}})
        }
      }
    },
    handleScroll: function () {
      this.headerSticky = window.scrollY > 50;
      this.showFilters = window.scrollY > 750 - (this.isMobile ? 100 : 0);
    },
  },
}
</script>