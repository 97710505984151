<template>
  <div class="search page">
    <div class="section">
      <div class="section__content uk-container uk-container-large">
        <div class="section__navigation" style="position: relative">
          <p><span><a href="/">Главная</a> |</span> Результаты поиска</p>
        </div>
      </div>
    </div>

    <div class="section section__banner section__banner_min">
      <div class="banner__background">
        <div class="uk-container uk-container-large">
          <p>Приобрести продукцию {{ appName }} можно онлайн или найти магазин в своем городе</p>
          <button class="uk-button uk-button-default pulse" uk-toggle="target: #modal">{{ 'Купить на маркетплейсе' }}</button>
        </div>
      </div>
    </div>

    <div class="section section__search">
      <div class="uk-container uk-container-large">
        <div class="container__catalog" style="padding-top: 60px">
          <div class="container__header container__search">
            Поиск по запросу: <span>{{ textSearch }}</span>
          </div>
          <div class="container__block" v-if="productsData && productsData.length > 0">
            <div class="container__columns">
              <CatalogItem
                v-for="(product, index) in productsData"
                :key="index"
                :item="product"
                :active="selectedProduct === product.id + '-prod'"
                :optimize="true"
                @clickOutside="clickOutside"
                @selectProduct="selectedProduct = product.id + '-prod'"
              />
            </div>
          </div>
          <div class="uk-margin-top" v-else-if="loading">
            <div class="loader loader-catalog-list">
              <div class="box box-flex" style="margin-bottom: 50px">
                <div class="item item-flex item-flex-inner" style="height: 504px">
                  <div class="item item-full item-col-4 item-bg-margin load-skeleton"></div>
                  <div class="item item-full item-col-4 item-bg-margin load-skeleton"></div>
                  <div class="item item-full item-col-4 item-bg-margin load-skeleton"></div>
                  <div class="item item-full item-col-4 item-bg-margin load-skeleton"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-margin-top" v-else>
            По вашему запросу ничего не найдено
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CatalogModal from '../components/CatalogModal'
import CatalogItem from '../components/CatalogItem'
import utils from '../utils'

export default {
  components: {
    CatalogItem,
    CatalogModal
  },
  data() {
    return {
      productsData: null,
      textSearch: '',
      selectedProduct: null,
      loading: false
    }
  },
  props: {
    isDevice: null,
  },
  methods: {
    clickOutside() {
      this.selectedProduct = null
    },
    resizeHeaderTheme() {
      if (this.isDevice === 'desktop') {
        this.$emit('updateHeaderTheme', {theme: 'white'})
      }
    },
    async searchData() {
      this.loading = true
      if (this.textSearch.length) {
        const {fetchData} = utils()
        const target = `?text=${this.textSearch}`
        const response = await fetchData('search', target)
        this.productsData = response ?? null
      }
      this.loading = false
    },
  },
  computed: {
    appName() {
      return process.env.VUE_APP_NAME
    }
  },
  watch: {
    isDevice() {
      this.resizeHeaderTheme()
    },
    $route: {
      handler() {
        this.textSearch = this.$route.query.text ?? ''
        this.searchData()
        const title = this.textSearch[0].toUpperCase() + this.textSearch.substring(1)
        document.title = `${title} - ${process.env.VUE_APP_NAME}`;
        window.scrollTo(0, 0);
      },
      deep: true
    }
  },
  mounted() {
    this.resizeHeaderTheme()
    this.textSearch = this.$route.query.text ?? ''
    this.searchData()
    setTimeout(() => {
      const title = this.textSearch[0].toUpperCase() + this.textSearch.substring(1)
      document.title = `${title} - ${process.env.VUE_APP_NAME}`;
    }, 200)
    window.scrollTo(0, 0);
  },
  metaInfo: {
    title: `Результаты поиска`,
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
}
</script>