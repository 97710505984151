import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueYandexMetrika from 'vue-yandex-metrika'
import VueAnalytics from 'vue-analytics';
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueRellax from 'vue-rellax'
import moment from 'moment'
import App from './App.vue'
import router from './router'
import store from './storage'
import 'uikit'
import 'uikit/dist/css/uikit.css'
import "./assets/font/stylesheet.css"
import "./assets/style.sass"
import './registerServiceWorker'

Vue.config.productionTip = false
Vue.prototype.$moment = moment
Vue.use(VueReCaptcha, { siteKey: `${process.env.VUE_APP_CAPTCHA_SITE_KEY}` })
Vue.use(VueMeta)
Vue.use(VueRellax)

const dbug = process.env.VUE_APP_ENV

if (process.env.VUE_APP_METRIC_YANDEX) {
  Vue.use(VueYandexMetrika, {
    id: process.env.VUE_APP_METRIC_YANDEX,
    env: dbug,
    router: router,
    options: { clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor:true }
  })
}

if (process.env.VUE_APP_METRIC_GOOGLE) {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_METRIC_GOOGLE,
    router: router,
    disabled: dbug !== 'production'
    //debug: {
    //  enabled: dbug !== 'production'
    //}
  })
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

Vue.directive('clickoutside', {
  inserted: (el, binding, vnode) => {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
  stopProp(event) {
    event.stopPropagation()
  },
})