<template>
	<div class="original page">
		<div class="section section__header">
			<div class="section__background section_anim" v-rellax="{ speed: -2 }">
				<img
					v-if="background"
					:src="require(`@/assets/img/backgrounds/${background.file}`)"
					alt="img"
				/>
			</div>
			<div class="section__content uk-container uk-container-large">
				<div class="section__navigation">
					<p>
						<span><a href="/">Главная</a> |</span> О продукции
					</p>
				</div>
				<div class="original_block">
					<div class="original_block__header">
						<div class="original_block__big-title">
							КАК ОТЛИЧИТЬ ОРИГИНАЛ АРОМАТИЗАТОРА ОТ ПОДДЕЛКИ?
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="background"
				class="section__place section__place_baikal section_anim"
				:class="{ section__place_dark: background.theme == 'dark' }"
			>
				<span v-html="background.title"></span>
			</div>
		</div>

		<div class="section section__main">
			<div class="uk-container uk-container-large">

				<div class="original_main">
					<div class="original_main__text original_main__fulltext">
						Не только запах сам по себе должен иметь значение при выборе ароматизатора для авто. 
						Важно также и то, насколько такое изделие является «долгоиграющим». 
						Но самое главное – это влияние ароматов, источаемых таким устройством на здоровье людей.
						Не секрет, что на рынке присутствует немало товара подобного рода, выпускаемого не вполне добросовестными производителями. 
						Сложно гарантировать безвредность ароматизатора, выпущенного малоизвестным производителем. 
						Тогда как высокое качество и безопасность ароматов EIKOSHA проверены временем. 
						Этим пользуются недобросовестные производители, выпуская подделки, маскирующиеся под изделия производителей с известным именем.
					</div>
					<div class="original_main__small_block">
						<div class="original_main__small_block__text">
							По внешнему виду изделия зачастую сделать это очень сложно – сейчас изготовители контрафактной продукции становятся изощрённее и стараются внешне копировать оригинал как можно точнее. 
							Приведённые ниже отличительные особенности упаковки помогут вам избежать приобретения поддельной продукции.
						</div>
						<div class="original_main__small_block__buttons_block">
							<a :href="apiUrl + '/get/filedownload?fileName=Обновление_упаковки_Эйкоша_в_2025'">
								<div class="original_main__small_block__buttons_block__svg">
									<img class="original_main__small_block__buttons_block__svg__hover" src="@/assets/img/svg/pdf.svg" alt="pdf"/>
								</div>
							</a>
							<div class="original_main__small_block__buttons_block__button"><a :href="apiUrl + '/get/filedownload?fileName=Обновление_упаковки_Эйкоша_в_2025'"> Обновления упаковки</a></div>
						</div>
					</div>

					<!--
					<div class="original_table">
						<div class="original_grid">
							<div class="original_item-1">Оригинал</div>
							<div class="original_item-2">Подделка</div>
							<div class="original_item_block">
								<img
									class="original_image"
									src="@/assets/img/original/Ellipse-2.png"
									alt="product"
								/>
								<div class="text_item">
									На задней стороне упаковки нет никаких отверстий
								</div>
							</div>
							<div class="original_item_block">
								<img
									class="original_image"
									src="@/assets/img/original/Ellipse-3.png"
									alt="product"
								/>
								<div class="text_item">
									На задней стороне упаковки есть круглое отверстие
								</div>
							</div>

							<div class="original_item_block">
								<img
									class="original_image"
									src="@/assets/img/original/Ellipse-4.png"
									alt="product"
								/>
								<div class="text_item">
									Оригинальная упаковка плотно заклеена
								</div>
							</div>

							<div class="original_item_block">
								<img
									class="original_image"
									src="@/assets/img/original/Ellipse-5.png"
									alt="product"
								/>
								<div class="text_item">
									Упаковка может открываться и закрываться многократно
								</div>
							</div>
							<div class="original_item_block">
								<img
									class="original_image"
									src="@/assets/img/original/Ellipse-6.png"
									alt="product"
								/>
								<div class="text_item">
									На упаковке всегда есть надпись «Made in Japan» и изображена
									баночка с пластиковой крышкой
								</div>
							</div>

							<div class="original_item_block">
								<img
									class="original_image"
									src="@/assets/img/original/Ellipse-7.png"
									alt="product"
								/>
								<div class="text_item">
									Надпись «Made in Japan» отсутствует. Баночка на упаковке
									изображена без пластиковой крышки
								</div>
							</div>
							<div class="original_item_block">
								<img
									class="original_image"
									src="@/assets/img/original/Ellipse-8.png"
									alt="product"
								/>
								<div class="text_item">
									На нижней стороне упаковки изображен логотип EIKOSHA, данные
									производителя и выбит номер партии товара
								</div>
							</div>

							<div class="original_item_block">
								<img
									class="original_image"
									src="@/assets/img/original/Ellipse-9.png"
									alt="product"
								/>
								<div class="text_item">
									На нижней стороне упаковки логотип, данные производителя,
									номер партии отсутствуют
								</div>
							</div>
							<div class="original_item_block">
								<img
									class="original_image"
									src="@/assets/img/original/Ellipse-10.png"
									alt="product"
								/>
								<div class="text_item">Фиксатор плотно держит наполнитель</div>
							</div>
							<div class="original_item_block">
								<img
									class="original_image"
									src="@/assets/img/original/Ellipse-11.png"
									alt="product"
								/>
								<div class="text_item">Фиксатор слабо держит наполнитель</div>
							</div>
							<div class="original_item_block">
								<img
									class="original_image"
									src="@/assets/img/original/Ellipse-12.png"
									alt="product"
								/>
								<div class="text_item">
									Крышка баночки должна выглядеть как на рисунке
								</div>
							</div>
							<div class="original_item_block">
								<img
									class="original_image"
									src="@/assets/img/original/Ellipse-13.png"
									alt="product"
									/>
								<div class="text_item">Фиксатор слабо держит наполнитель</div>
							</div>
							<div class="original_item_block">
								<img
									class="original_image"
									src="@/assets/img/original/Ellipse-14.png"
									alt="product"
								/>
								<div class="text_item">Запахи приятные и естественные</div>
							</div>
							<div class="original_item_block">
								<img
									class="original_image"
									src="@/assets/img/original/Ellipse-15.png"
									alt="product"
								/>
								<div class="text_item">Запахи резкие и некачественные</div>
							</div>
						</div>
					</div>
					-->

					<h3 class="original_item__title">
						Модификация внутри “консервы”
					</h3>
					<div class="conserve">
						<div class="conserve-col1">
							<div class="conserve-col1-title">
								Версия 1.
							</div>
							<div class="conserve-col1-descrption">
								Нетканый материал с ароматической пропиткой расположен вокруг мелового наполнителя.
							</div>
							<div class="conserve-col1-image">
								<img src="@/assets/img/original/Rectangle.png" alt="Rectangle">
							</div>
						</div>
						<div class="conserve-col2">
							<div class="conserve-col2-title">
								Версия 2 - новая модификация (с октября 2023).
							</div>
							<div class="conserve-col2-descrption">
								Объем ароматической пропитки остался прежним, но абсорбция нового 
								листового нетканого материала (вокруг керамического элемента) увеличена 
								по сравнению с нынешним вариантом. Также увеличена площадь контакта 
								между керамическим элементом и оболочкой, что позволяет аромату 
								распространяться лучше.
							</div>
							<div class="conserve-col2-images">
								<img class="conserve-col2-images-1" src="@/assets/img/original/Rectangle2.png" alt="Rectangle">
								<img class="conserve-col2-images-2" src="@/assets/img/original/Rectangle3.png" alt="Rectangle">
							</div>
						</div>
					</div>
					<h3 class="original_item__title">
						Боковая сторона упаковки
					</h3>
					<div class="side">
						<div class="side-col1">
							<div class="side-col-title">Версия 1.</div>
							<img src="@/assets/img/original/e9910231.png" alt='png'/>
						</div>
						<div class="side-col2">
							<div class="side-col-title">Версия 2 - обновленная упаковка.</div>
							<img src="@/assets/img/original/e8918283.png" alt='png'/>
						</div>
						<div class="side-col2">
							<div class="side-col-title">Версия 3 - обновленная упаковка.</div>
							<img src="@/assets/img/original/pear-fressia.png" alt='png'/>
						</div>
					</div>
					<!-- <div class="original_item__grid">
						<div class="original_item__grid-col">
							<p>Версия 1</p>
							<div class="image">
								<img src="@/assets/img/original/e9910231.png" alt='png'/>
							</div>
						</div>

						<div class="original_item__grid-col">
							<p>Версия 2 – обновленная упаковка</p>
							<div class="image">
								<img src="@/assets/img/original/e8918283.png" alt='png'/>
							</div>
						</div>

						<div class="original_item__grid-col">
							<p>Версия 3 - обновленная упаковка.</p>
							<div class="image">
								<img src="@/assets/img/original/pear-fressia.png" alt='png'/>
							</div>
						</div>
					</div> -->

					<h3 class="original_item__title">
						Задняя сторона упаковки
					</h3>
					<div class="original_item__grid">
						<div class="original_item__grid-col">
							<p>Версия 1</p>
							<div class="image">
								<img src="@/assets/img/original/b41231244.png" alt='png'/>
							</div>
						</div>

						<div class="original_item__grid-col">
							<p>Версия 2 – обновленная упаковка</p>
							<div class="image">
								<img src="@/assets/img/original/b42341122.png" alt='png'/>
							</div>
						</div>
					</div>
	
					<h3 class="original_item__title">
						Нижняя часть упаковки
					</h3>
					<div class="original_item__grid original_item__grid_jc-around">
						<div class="original_item__grid-col">
							<p></p>
							<div class="image">
								<img src="@/assets/img/original/s34123114.png" alt='png'/>
							</div>
						</div>

						<div class="original_item__grid-col">
							<p></p>
							<div class="image">
								<img src="@/assets/img/original/s52512352.png" alt='png'/>
							</div>
						</div>
					</div>

				
					<h3 class="original_item__title">
						Другие отличительные особенности
					</h3>
					<div class="original_item__grid">
						<div class="original_item__grid-col" style="width: 33.3%">
							<div class="image">
								<img src="@/assets/img/original/f12312312.png" alt='png'/>
							</div>
						</div>

						<div class="original_item__grid-col" style="width: 33.3%">
							<div class="image">
								<img src="@/assets/img/original/f16231352.png" alt='png'/>
							</div>
						</div>

						<div class="original_item__grid-col">
							<div class="image">
								<img src="@/assets/img/original/f62341263.png" alt='png'/>
							</div>
						</div>
					</div>

					<h3 class="original_item__title">
						Варианты крышек на “консерве”
					</h3>
					<div class="original_item__grid">
						<div class="original_item__grid-col">
							<p>Версия 1</p>
							<div class="image">
								<img src="@/assets/img/original/g5211231.png" alt='png'/>
							</div>
						</div>

						<div class="original_item__grid-col">
							<p>Версия 2 – обновленная упаковка</p>
							<div class="image">
								<img src="@/assets/img/original/g3112342.png" alt='png'/>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<MouseAnim v-if="isDevice == 'desktop'" />
	</div>
</template>

<script>
import MouseAnim from "../components/MouseAnim";
import BackgroundsJson from "@/assets/data/backgrounds.json";

export default {
  components: {
	MouseAnim,
  },
  props: {
	isDevice: null,
  },
  data() {
	return {
		apiUrl: process.env.VUE_APP_BACKEND_URL,
	}
  },
  computed: {
		background() {
			const backgrounds = BackgroundsJson.filter((item) => {
				return item.route === this.$route.path;
			});
			if (backgrounds) {
				let b = backgrounds[Math.floor(Math.random() * backgrounds.length)];
				this.$emit("updateHeaderTheme", { theme: b.header });
				return b;
			}
			return null;
		},
		appName() {
			return process.env.VUE_APP_NAME;
		},
	},
};
</script>


<style scoped>

.conserve {
	display: flex;
	flex-direction: row;
	column-gap: 50px;
	width: 100%;
	font-weight: 500;
	@media screen and (max-width: 920px) {
		flex-direction: column;
		row-gap: 30px;
	}
}
.conserve-col1 {
	width: 45%;
	display: flex;
	flex-direction: column;
	row-gap: 30px;
	.conserve-col1-image {
		display: flex;
		justify-content: center;
		img {
			width: 80%;
			@media screen and (max-width: 420px) {
				width: 100%;
			}
		}
	}
	@media screen and (max-width: 920px) {
		width: 100%;
	}
}
.conserve-col2 {
	width: 55%;
	display: flex;
	flex-direction: column;
	row-gap: 30px;
	.conserve-col2-images {
		display: flex;
		flex-direction: row;
		img {
			width: 50%;
		}
		@media screen and (max-width: 580px) {
			flex-direction: column;
			justify-content: center;
			align-items: center;
			img {
				width: 80%;
				@media screen and (max-width: 420px) {
					width: 100%;
				}
			}
		}
		
	}
	@media screen and (max-width: 920px) {
		width: 100%;
	}
}

.side {
	display: flex;
	flex-direction: row;
	column-gap: 30px;
	font-weight: 500;
	.side-col-title {
		min-height: 60px;
		/* border: 1px solid black; */
	}
	.side-col1 {
		img {
			width: 95%;
			padding: 20px;
			@media screen and (max-width: 920px) {
				padding: 0;
			}
		}
	}
	@media screen and (max-width: 920px) {
		flex-direction: column;
		row-gap: 20px;
		align-items: center;
	}
	@media screen and (max-width: 580px) {
		.side-col2  {
			display: flex;
			flex-direction: column;
			align-items: center;
			img {
				width: 60%;
			}
		}
	}
}

</style>