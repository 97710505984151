<template>
  <div class="company page">
    <div class="section section__header">
      <div class="section__background section_anim" v-rellax="{speed: -3 }">
        <img
            v-if="background"
            :src="require(`@/assets/img/backgrounds/${background.file}`)"
            alt="img"
        />
      </div>
      <div class="section__content uk-container uk-container-large">
        <div class="section__navigation section__navigation_light">
          <p><span><a href="/">Главная</a> |</span> О компании</p>
        </div>

        <div class="section__title">
          {{ appName }} – это <br>
          истинно японские <br>
          ароматизаторы, <br>
          качество которых <br>
          всегда на <br>
          высочайшем уровне.
        </div>
      </div>
      <div
          v-if="background"
          class="section__place section_anim"
          :class="{ section__place_dark: background.theme == 'dark' }"
      >
        <span v-html="background.title"></span>
      </div>
    </div>

    <div class="section section__main">
      <div class="uk-container uk-container-large">
        <div class="section__container">
          <div class="section__company">
            <div class="block">
              <div class="year block_sm top-padd">
                <div class="year__numb">
                  {{ '1951' }}
                </div>
                <p class="year__text-left">
                  Была основана компания {{ appName }}
                </p>
              </div>

              <div class="year block_bg">
                <div class="year__numb">
                  {{ '1980' }}
                </div>
                <p>
                  {{ appName }} произвела настоящую революцию в мире автомобильных освежителей воздуха, выпустив
                  на рынок ароматизаторы на меловой основе – металлические герметичные баночки с меловым
                  ароматизированным наполнителем внутри.
                </p>
              </div>
            </div>

            <div class="block">
              <div class="advantage block_sm">
                Преимущества ароматизаторов компания {{ appName }}
              </div>
              <div class="advantage block_bg">
                <div class="advantage__item">
                  <div class="advantage__icon">
                    <div class="eik-i eik-i-sign"></div>
                  </div>
                  <p class="advantage__title">
                    Вся продукция {{ appName }} производится только в Японии <br>
                    г. Киото, это гарантирует высокие стандарты качества.
                  </p>
                </div>

                <div class="advantage__item">
                  <div class="advantage__icon">
                    <div class="eik-i eik-i-flasks"></div>
                  </div>
                  <p class="advantage__title">
                    Все ароматы уникальны и разрабатываются в собственной <br>
                    лаборатории по оригинальным запатентованным технологиям.
                  </p>
                </div>

                <div class="advantage__item">
                  <div class="advantage__icon">
                    <div class="eik-i eik-i-bottle"></div>
                  </div>
                  <p class="advantage__title">
                    Для производства парфюмерной композиции закупается только <br>
                    высококачественное сырье и оригинальные эфирные масла, что <br>
                    обеспечивает стойкость и натуральность ароматов
                  </p>
                </div>

                <div class="advantage__item">
                  <div class="advantage__icon">
                    <div class="eik-i eik-i-list"></div>
                  </div>
                  <p class="advantage__title">
                    Многоуровневое тестирование перед запуском аромата: фокус-<br>
                    группа – тестовая партия – тестирование в точках продаж на <br>
                    внутреннем рынке – запуск в продажи по всему миру.
                  </p>
                </div>
              </div>
            </div>

            <div class="block block_right">
              <div class="block_bg block__image">
                <img
                    src="@/assets/img/banner_cmp_md.png"
                    alt="banner"
                />
              </div>
            </div>

            <div class="block">
              <div class="block_inline">
                <div class="block__head">
                  На данный момент их количество превысило 30.
                </div>
                <div class="block__content">
                  Все предлагаемые компанией ароматы настолько гениальны и неповторимы, что их <br>
                  легко можно спутать с запахами от лучших парфюмерных домов мира. Поэтому все <br>
                  автомобильные  ароматизаторы от компании {{ appName }} – это не просто освежители <br>
                  воздуха, это настоящие автомобильные парфюмы.
                </div>
              </div>
            </div>

            <div class="block">
              <div class="block_md">
                <div class="product__image">
                  <img src="@/assets/img/products/prodmain.png" alt="product"/>
                </div>
              </div>
              <div class="block_bg block_col">
                <div class="product__items">
                  <div><img src="@/assets/img/products/Giga-V99.png" alt="product"/></div>
                  <div><img src="@/assets/img/products/Giga-V91.png" alt="product"/></div>
                  <div><img src="@/assets/img/products/55726.png" alt="product"/></div>
                  <div><img src="@/assets/img/products/5573.png" alt="product"/></div>
                  <div><img src="@/assets/img/products/Giga-V93.png" alt="product"/></div>
                  <div><img src="@/assets/img/products/Giga-V98.png" alt="product"/></div>
                  <div><img src="@/assets/img/products/5570.png" alt="product"/></div>
                  <div><img src="@/assets/img/products/5575.png" alt="product"/></div>
                  <div><img src="@/assets/img/products/5571.png" alt="product"/></div>
                </div>

                <p class="product__text">
                  Для тех, кто не желает менять понравившийся вариант корпуса <br>
                  ароматизатора, существуют, сменные картриджи
                </p>
              </div>
            </div>

            <div class="block">
              <div class="block_bg block_col block__head">
                <p style="margin-bottom: 100px">
                  Вся продукция компании производится только в Японии, поэтому, приобретая торговую 
                  марку {{ appName }}, автолюбитель может быть уверен в высоком качестве получаемого товара.
                </p>
              </div>
              <div class="block__banner">
                <img
                    src="@/assets/img/banner_cmp_bg.png"
                    alt="banner"
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <MouseAnim :theme="background ? background.theme : ''"/>
  </div>
</template>

<script>
import MouseAnim from '../components/MouseAnim'
import BackgroundsJson from '@/assets/data/backgrounds.json'

export default {
  components: {
    MouseAnim,
  },
  computed: {
    background() {
      const backgrounds = BackgroundsJson.filter(item => {
        return item.route === this.$route.path
      })
      if (backgrounds) {
        let b = backgrounds[Math.floor(Math.random() * backgrounds.length)]
        this.$emit('updateHeaderTheme', {theme: b.header})
        return b
      }
      return null
    },
    appName() {
      return process.env.VUE_APP_NAME
    }
  },
  metaInfo: {
    title: 'О компании',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
}
</script>