<template>
  <div class="footer">
    <div class="uk-container uk-container-large">
      <div class="footer_container">
        <div class="footer__content footer_row">
          <div class="footer-search__button">
            <button
                @click="search($event)"
                class="btn-search"
                aria-label="Поиск"
            >
              <div class="eik-i eik-i-search"></div>
            </button>
            <input
                type="text"
                ref="input-search-sm"
                class="input-search"
                placeholder="Поиск на сайте"
                @keypress.enter="search($event)"
            />
          </div>
        </div>
        <div class="footer__content footer_row">
          <div class="footer__contact">
            <span>{{ 'г. Владивосток, ул. Днепровская, 104' }}</span>
            <a href="tel://84232790279">{{ '8 (423) 279 02 79' }}</a>
          </div>
        </div>
        <div class="footer__content footer__flex footer_row">
          <div class="footer_col">
            <button
                @click="$emit('feedback', { enabled })"
                class="uk-button uk-button-default pulse"
            >
              {{ 'Написать нам' }}
            </button>
          </div>
          <div class="footer__logo footer_col">
            <a href="/">
              <img src="@/assets/img/logo.svg" alt="eikosha"/>
            </a>
          </div>
        </div>
        <div class="footer__content footer__flex footer_row  footer__flex_end">
          <div class="footer_col">
            <div class="footer-menu">
              <div class="footer-menu__container">
                <div
                    v-for="(item, i) in menu"
                    :key="i"
                    class="footer-menu__item">
                  <a :href="item.href">
                    {{ item.title }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="footer__logo">
            <a href="/">
              <img src="@/assets/img/logo.svg" alt="eikosha"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuJson from '@/assets/data/menu.json';

export default {
  name: 'footer-component',
  data() {
    return {
      menu: MenuJson,
      enabled: true,
    }
  },
  methods: {
    search() {
      let input = this.$refs['input-search-sm']

      if (input) {
        input.focus()
        if (input.value.length > 2 && this.$route.query.text !== input.value) {
          this.$router.push({name: 'search', query: {text: input.value}})
          input.value = ''
          input.blur()
          window.scrollTo(0,0);
        }
      }
    }
  }
}
</script>