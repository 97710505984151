import axios from 'axios';

export default function () {
  const getStaticImage = (type, name) => `${process.env.VUE_APP_SERVICES_STATIC_URL}/${type}/${name}`;

  const isObjectEmpty = (obj) => {
    for (var i in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, i)) {
        return false;
      }
    }
    return true;
  }

  const fetchData = async (action, target) => {
    let content = []
    try {
      await axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/${action}/${target}`)
        .then((response) => {
          content = response.data
        });
    } catch (e) {
      console.log('We have the error', e)
    }
    return content
  };

  const getRequireJson = (filename) => {
    try {
      return require("./assets/data/" + filename)
    } catch (e) {
      console.log('We have the error', e)
    }
    return null
  };

  return { getStaticImage, isObjectEmpty, fetchData, getRequireJson };
}
