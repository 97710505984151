<template>
  <div id="modal-item" class="uk-flex-top eik-modal" uk-modal>
    <div v-if="catalogItemEnabled" class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-padding-remove">
      <div class="feedback__close uk-modal-close-default" @click="$emit('closeModal')"  uk-toggle="target: #modal-item">
        <div class="eik-i eik-i-close"></div>
      </div>
      <CatalogItem
          :item="modalItem.item"
          :accessories="modalItem.accessories"
          :active="true"
          :popup="true"
          :optimize="optimize"
      />
    </div>
  </div>

</template>

<script>
import CatalogItem from '../components/CatalogItem'

export default {
  components: {
    CatalogItem
  },
  props: {
    modalItem: null,
    optimize: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    catalogItemEnabled() {
      return this.modalItem && (this.modalItem.item || this.modalItem.accessories)
    }
  },
}
</script>