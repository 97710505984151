<template>
  <div class="feedback" :class="{ feedback_open : enabled }">
    <div @click="hide" class="feedback__fixed">
      <div class="feedback__modal uk-container uk-container-small">
        <div class="feedback__container">
          <div class="feedback__close" @click="$emit('getOpenStatus', { enabled: false })">
            <div class="eik-i eik-i-close"></div>
          </div>
          <div class="feedback__content">
            <div class="feedback-form">
              <div class="feedback-form__header">
                {{ 'Обратная связь' }}
              </div>
              <div class="feedback-form__fields">
                <div class="field" :class="{ error : errors && errors.inputs && errors.inputs.name }">
                  <input
                      id="name"
                      class="uk-input"
                      type="text"
                      name="name"
                      v-model="model.name"
                      @focus="checkInputValue"
                      @blur="checkInputValue"
                  />
                  <label for="name" class="placeholder">Укажите имя</label>
                  <div class="field__error" v-if="errors && errors.inputs && errors.inputs.name">
                    {{ errors.inputs.name }}
                  </div>
                </div>
                <div class="field" :class="{ error : errors && errors.inputs && errors.inputs.email }">
                  <input
                      id="email"
                      class="uk-input"
                      type="email"
                      v-model="model.email"
                      @focus="checkInputValue"
                      @blur="checkInputValue"
                  />
                  <label for="email" class="placeholder">Укажите почту</label>
                  <div class="field__error" v-if="errors && errors.inputs && errors.inputs.email">
                    {{ errors.inputs.email }}
                  </div>
                </div>
              </div>
              <div class="feedback-form__fields">
                <div class="field" :class="{ error : errors && errors.inputs && errors.inputs.message }">
                    <textarea
                        id="message"
                        class="uk-textarea"
                        type="text"
                        rows="4"
                        v-model="model.message"
                        @focus="checkInputValue"
                        @blur="checkInputValue"
                    ></textarea>
                  <label for="message" class="placeholder">Ваше сообщение</label>
                  <div class="field__error" v-if="errors && errors.inputs && errors.inputs.message">
                    {{ errors.inputs.message }}
                  </div>
                </div>
              </div>

              <div class="feedback-confirm">
                <button
                    class="uk-button uk-button-default pulse"
                    @click="recaptcha"
                    :disabled="!buttonEnabled"
                >
                  <span uk-spinner v-if="spinnerEnabled"></span>
                  {{ 'Написать нам' }}
                </button>

                <div class="feedback-status feedback-status__errors" v-if="errors">
                  <span>Произошла ошибка!</span>
                  {{ errors.message }}
                </div>

                <div class="feedback-status feedback-status__success" v-if="status">
                  <span>Готово!</span>
                  {{ 'Сообщение успешно отправлено' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    enabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      errors: null,
      status: null,
      model: {
        name: null,
        email: null,
        message: null,
      },
      buttonEnabled: true,
      spinnerEnabled: false,
    }
  },
  computed: {
    requestApi() {
      return `${process.env.VUE_APP_BACKEND_URL}/post-form`
    }
  },
  methods: {
    checkInputValue(event) {
      if (!event.target.value) {
        event.target.nextSibling.classList.toggle('placeholder_top');
      }
    },
    hide(event) {
      if (!event.target.closest('.feedback__modal')) {
        this.$emit('getOpenStatus', {enabled: false})
      }
    },
    postFeedbackForm(data) {
      this.buttonEnabled = false
      this.errors = null
      let st = setTimeout(() => this.spinnerEnabled = true, 200);
      axios
          .post(this.requestApi, data)
          .then((response) => {

            clearTimeout(st)
            this.status = response.data.success
            this.buttonEnabled = true
            this.spinnerEnabled = false

            if (response.data.success) {
              for (let key in this.model) {
                this.model[key] = null
              }

              const pht = document.querySelectorAll('.placeholder_top')
              pht?.forEach(p => p.classList.remove('placeholder_top'))

              setTimeout(() =>
                  this.$emit('getOpenStatus', {enabled: false}),
                  200
              )
            } else {
              this.errors = response.data
            }
          });
    },
    async recaptcha() {
      if (this.buttonEnabled) {
        await this.$recaptchaLoaded()

        this.model['token'] = await this.$recaptcha('login')
        this.postFeedbackForm(this.model)
      }
    },
  }
}
</script>