<template>
  <div class="store-map">
    
    <div class="switch-map" v-if="isDevice !== 'desktop'">
      <div class="switch-title">
        <h2>Офисы компании</h2>
      </div>
      <div class="switch-map__buttons">
        <div class="switch-map__button">
          <button
              class="map-point__button"
              :class="{'is-active': switchListMap === 'list'}"
              @click="switchListMap = 'list'"
          >
            Списком
          </button>
        </div>
        <div class="switch-map__button">
          <button
              class="map-point__button"
              :class="{'is-active': switchListMap === 'map'}"
              @click="switchListMap = 'map'"
          >
            На карте
          </button>
        </div>
      </div>
    </div>

    <div class="assurance-body">
      <div class="map-navigation">
        <div class="map-navigation__selection">

          <div class="selection-item">
            <input
                name="city"
                class="uk-input map-selector"
                placeholder="Выберите город"
                autocomplete="disabled"
                @keyup="insertedSearchFilter"
                @focus="showListCities = true"
                @blur="$event.target.nextSibling.matches(':hover') ? '' : showListCities = false"
                v-model="selectedCityName"
            />
            <div class="selection-item__list" :class="{'is-active' : showListCities}">
              <div v-for="(city, index) in initDataCities"
                   :key="index"
                   v-show="showBySearch(city)"
                   @click="onChangeCity(city)"
                   class="selection-item__list-item"
              >
                {{ city.name }}
              </div>
            </div>
          </div>
          <div class="selection-item__location" @click="getLocationByClientIP">
            <div class="eik-i eik-i-location" v-if="!loadLocation"></div>
            <div class="location__spin" uk-spinner v-else></div>
          </div>
        </div>

        <div class="map-list" v-if="isDevice === 'desktop' || switchListMap === 'list'">
          <div
              class="map-point"
              v-for="(point, index) in initDataPoints"
              :key="index"
              v-show="!selectedCityName || point.city == selectedCityName"
          >
            <p class="map-point__type">{{ point.type }}</p>
            <p class="map-point__title map-point__title_light" v-html="fixTransferText(point.address)"></p>

            <button
                class="map-point__button map-point__block"
                :class="{'is-active': selectedPoint === point.id}"
                @click="(selectedPoint = point.id) && (initCoords = point.coords)"
            >
              На карте
            </button>

            <div v-if="point.working_time" class="map-point__row" style="display: block">
              Часы работы:
              <div class="working-time">
                <div class="working-time__status">
                  {{ workingTimeStatus(point.working_time_array, point.utc) }}
                </div>
                <div
                    class="working-time__message"
                    @click.stop="showListWorkingTime = point.id"
                    v-clickoutside="clickOutside"
                >
                <span>
                  {{ workingTimeMessage(point.working_time_array, point.utc) }}
                </span>
                  <div class="working-time__list" :class="{'is-active' : showListWorkingTime === point.id}">
                    <div class="list__container">
                      <div
                          v-for="(item, ii) in point.working_time_array"
                          :key="ii"
                          class="list__item"
                          :class="{list_active : dayCurrent == item.day }"
                      >
                        <span>{{ item.day }}</span> {{ item.start ? item.start : 'выходной' }}  {{ item.end ? '- ' + item.end : '' }}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div class="map-point__row" >
              Телефон:
              <span v-if="point.phone">
                <template v-for="(item, index) in point.phone">
                  <div :key="index">{{ item }}</div>
                </template>
              </span>
              <span v-else class="nf-point">не указан</span>
            </div>
            <div class="map-point__row">
              E-mail:
              <span v-if="point.email && point.email.length">
                <template v-for="(item, index) in point.email">
                  <div :key="index">{{ item }}</div>
                </template>
              </span>
              <span v-else class="nf-point">не указан</span>
            </div>
            <div class="map-point__row row_pd">
              Cайт:
              <a href="https://uniqom.ru/" target="_blank">https://uniqom.ru/</a>
            </div>

            <div class="map-point__info" v-if="selectedCityName == 'Владивосток'">
              <p class="map-point__title map-point__title_light">
                Отдел по работе со странами СНГ
              </p>
              <div class="map-point__row">
                Руководитель отдела:<br>  Витвицкий Дмитрий Станиславович
              </div>
              <div class="map-point__row">
                Тел.: + 7 (423) 279-02-79, доб.5626
              </div>
              <div class="map-point__row">
                Email: d.vitvitsky@uniqom.ru
              </div>
              <div class="map-point__row">
                Время работы: <br>
                Пн.-Пт.с 10.00 до 19.00. Сб.- Вс.- выходной
              </div>
            </div>
          </div>
        </div>
        <div class="map-message">
          <span @click="$emit('feedback')">Сообщите об ошибке</span>
        </div>
      </div>

      <div
          class="interactive-map"
          v-show="isDevice === 'desktop' || switchListMap === 'map'"
      >
        <yandex-map
            v-if="initCoords"
            :settings="settings"
            :coords="initCoords"
            :controls="controls"
            :zoom="15"
            ref="ymap"
        >
          <ymap-marker
              v-for="(point, index) in initDataPoints"
              :key="index"
              :marker-id="index"
              :coords="point.coords"
              :hint-content="point.address"
              :icon="selectedPoint === point.id ? selectedMarkerIcon : markerIcon"
              :balloon-template="point.balloon"
              @click="(selectedPoint = point.id) && (initCoords = point.coords)"
          ></ymap-marker>
        </yandex-map>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { yandexMap, ymapMarker } from 'vue-yandex-maps';
import utils from '../utils';

export default {
  components: {
    yandexMap,
    ymapMarker
  },
  props: {
    isDevice: null,
  },
  data() {
    return {
      apiCities: 'offices/cities',
      apiPoints: 'offices',
      initDataCities: [],
      initDataPoints: [],
      initCoords: null,
      selectedCity: null,
      selectedSearch: [],
      selectedCityName: '',
      showListCities: false,
      selectedPoint: null,
      settings: {
        apiKey: process.env.VUE_APP_YANDEX_API_KEY,
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1',
      },
      markerIcon: {
        layout: 'default#image',
        imageHref: '/img/markerDefault.png',
        imageSize: [62, 56],
        imageOffset: [-31, -56],
      },
      selectedMarkerIcon: {
        layout: 'default#image',
        imageHref: '/img/markerSelected.png',
        imageSize: [62, 56],
        imageOffset: [-31, -56],
      },
      controls: ['zoomControl', 'typeSelector', 'fullscreenControl'],
      showListWorkingTime: false,
      loadLocation: false,
      switchListMap: 'list',
    }
  },
  computed: {
    dayCurrent() {
      const days = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];
      const date = new Date();
      return days[date.getDay()];
    },
    timeCurrent() {
      return this.$moment(new Date()).format('H:mm')
    },
    detectRobot() {
      // eslint-disable-next-line
      const robots = new RegExp([
        /bot/,/spider/,/crawl/,
        /APIs-Google/,/AdsBot/,/Googlebot/,/Googlebot-Mobile/,
        /mediapartners/,/Google Favicon/,/Googlebot-Image/,
        /FeedFetcher/,/Google-Read-Aloud/,
        /DuplexWeb-Google/,/googleweblight/,
        /bing/,/yandex/,/baidu/,/duckduck/,/yahoo/,
        /ecosia/,/ia_archiver/,
        /facebook/,/instagram/,/pinterest/,/reddit/,
        /slack/,/twitter/,/whatsapp/,/youtube/,
        /semrush/,
      ].map((r) => r.source).join("|"),"i");

      return robots.test(navigator.userAgent);
    }
  },
  methods: {
     timeToDate: function(time) {
      const chunks = time.split(':')
      const date = new Date()

      date.setHours(Number(chunks[0]))
      date.setMinutes(Number(chunks[1]))

      return date;
    },
    workingTimeStatus: function(dateArray, utc) {
      const dateCurrent = dateArray.find(date => {return this.dayCurrent == date.day})
      const now = new Date()

      let curOffset = new Date().getTimezoneOffset() / 60 * -1
      now.setHours(now.getHours() - ((+curOffset) - (+utc)))

      if (dateCurrent && dateCurrent['start'] && dateCurrent['end']) {
        let dateStart = this.timeToDate(dateCurrent['start'], curOffset, utc)
        let dateEnd = this.timeToDate(dateCurrent['end'], curOffset, utc)

        if (now >= dateStart && now <= dateEnd) return 'Открыто'
      }
      return 'Закрыто'
    },
    workingTimeMessage: function(dateArray, utc) {
      const dateCurrent = dateArray.find(date => {return this.dayCurrent == date.day})
      const now = new Date()
      let curOffset = new Date().getTimezoneOffset() / 60 * -1
      now.setHours(now.getHours() - ((+curOffset) - (+utc)))

      if (dateCurrent) {

        if (dateCurrent['start']) {
          if (now < this.timeToDate(dateCurrent['start'])) return 'Откроется в ' + dateCurrent['start']
        }

        if (!dateCurrent['end'] || now > this.timeToDate(dateCurrent['end'])) {

          let dateOpen = null
          let index = dateArray.indexOf(dateCurrent)
          let count = 0
          while (dateOpen === null || count === 7) {
            if (index + 1 === dateArray.length) index = 0
            else index++

            dateOpen = dateArray[index]['start'] ? dateArray[index]['start'] : null
            count++
          }
          return 'Откроется в ' + dateOpen
        }

        return 'Закроется в ' + dateCurrent['end']
      }

      return 'Не работает'
    },
    setBlockTime: function () {
      let blockTime = new Date()
      blockTime.setDate(blockTime.getDate() + 4)
      localStorage.setItem('blockTime', +blockTime)
    },
    getInitDataCities: async function () {
      const {fetchData} = utils()
      let response = await fetchData('get', this.apiCities)
      this.initDataCities = response ? response : []

      const blockTime = localStorage.getItem('blockTime')

      if (blockTime !== null) {
        if (+new Date() >= parseInt(blockTime)) {
          localStorage.removeItem('blockTime')
          localStorage.removeItem('location')
        } else {

          const location = JSON.parse(localStorage.getItem('location'))
          this.setBlockTime()

          this.selectedCity = this.initDataCities.find(city => {
            return city.name === location.name
          })

          if (!this.selectedCity) return -1

          this.selectedCityName = this.selectedCity.name
          this.initCoords = [location['lat'], location['lon']]

          this.getInitDataPoints()
          return 1
        }
      }

      this.getLocationByClientIP()
      return 0
    },
    getInitDataPoints: async function () {
      const {fetchData} = utils()
      const targetGetPoints = `${this.apiPoints}`
      const response = await fetchData('get', targetGetPoints)
      if (!response) return -1

      let temp = response
      for (let i = 0; i < response.length; i += 1) {
        if (!temp[i].coords) {
          temp[i].coords = [
            temp[i].address.latitude,
            temp[i].address.longitude,
          ];
          const {icon} = temp[i]
          delete temp[i].icon
          temp[i].icon = {content: icon}
          temp[i].address = temp[i].address.name
        }
      }
      this.initDataPoints = temp
    },
    getLocationByClientIP: async function () {
      let IP = null
      if (this.loadLocation) return -1

      this.loadLocation = true
      const targetGetIP = process.env.VUE_APP_CLIENT_IP

      if (this.detectRobot === false) {
        await axios
            .get(targetGetIP)
            .then((res) => IP = res.data);
      }

      if (IP) {
        const targetGetLocal = `${process.env.VUE_APP_GEO_URL}/${process.env.VUE_APP_GEU_KEY}/json/${IP}`
        let response = null
        await axios
            .get(targetGetLocal)
            .then((res) => response = res.data);

        this.loadLocation = false
        if (!response) return -1

        let location = {
          lat: response.city.lat,
          lon: response.city.lon,
          name: response.city.name_ru
        }

        this.selectedCity = this.initDataCities.find(city => {
          return city.name === location.name
        })

        this.initCoords = [location['lat'], location['lon']]
        this.selectedCityName = this.selectedCity?.name
        localStorage.setItem('location', JSON.stringify(location))
        this.setBlockTime()
      } else {
        //default coords - Msk
        this.initCoords = [55.4507, 37.3656]
        this.loadLocation = false
      }
    },
    showBySearch(value) {
      if (!this.selectedSearch || this.selectedSearch.length < 2) return true;
      return (value.name && value.name.toLowerCase().includes(this.selectedSearch.toLowerCase()));
    },
    onChangeCity(city) {
      this.showListCities = false
      this.selectedCity = city
      this.selectedCityName = city.name
      this.switchListMap = 'list'
    },
    insertedSearchFilter(event) {
      this.selectedSearch = event.target.value
      this.$forceUpdate();
    },
    clickOutside() {
      this.showListWorkingTime = null
    },
    fixTransferText(str) {
      const pos = str.lastIndexOf(',') + 2
      return str.substr(0, pos) + '<nobr>' + str.substr(pos) + '</nobr>';
    },
  },
  watch: {
    selectedPoint(value) {
      if (value && this.isDevice !== 'desktop') {
        this.switchListMap = 'map'
      }
    }
  },
  mounted() {
    this.getInitDataCities()
  }
}
</script>