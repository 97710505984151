<template>
  <div class="section_bg">
    <div class="section__top">
      <div class="section__description">
        «Юником» - один из ведущих российский дистрибьюторов
        автотоваров. По целому ряду товарных групп компания
        является лидером автомобильного рынка. Многие брэнды
        поставляются на российский рынок по эксклюзивным
        соглашениям с производителями.
      </div>
    </div>

    <div class="section__info">
      <div class="info__item">
        <div class="info__numb">
          {{ year }} лет
        </div>
        <div class="info__text">
          работы компании
        </div>
      </div>

      <div class="info__item">
        <div class="info__numb">
          96%
        </div>
        <div class="info__text">
          довольных клиентов
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    year() {
      return (new Date()).getFullYear() - 1995;
    }
  }
}
</script>