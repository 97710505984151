<template>
    <div class="smell page">
        <div class="section section__header">
          <div class="section__background section_anim" v-rellax="{speed: -2 }">
            <img
                v-if="background"
                :src="require(`@/assets/img/backgrounds/${background.file}`)"
                alt="img"
            />
          </div>
            <div class="section__content uk-container uk-container-large">
                <div class="section__navigation section__navigation_light">
                 <p><span><a href="/">Главная</a> |</span> О продукции</p>
                </div>
                <div class="smell_block">
                    <div class="smell_block__header">
                        <div class="smell_block__title">
                            КАК ВЫБРАТЬ «ПРАВИЛЬНЫЙ» АРОМАТ?
                        </div>
                        <div class="smell_block__text_top">
                           
                            При выборе ароматизатора для автомобиля важны многие
                            моменты: ваши обонятельные предпочтения, специфика запаха, 
                            от которого вы хотите избавиться раз и навсегда, известность 
                            фирмы-производителя ароматизатора и многое другое. 
                            
                        </div>
                        <div class="smell_block__text_bottom">

                            Какой из запахов салона раздражает вас? Если сомнительное амбре исходит от резинового коврика, есть смысл, наконец, расстаться с дешевой резиной и купить нечто
                            более приличное. Ароматизаторы для автомобиля не смогут окончательно «убить» и такой запах, как табачный душок обивки кресел.

                        </div>
                    </div>
                </div>
                <div
                    v-if="background"
                    class="section__place section_anim"
                    :class="{ section__place_dark: background.theme == 'dark' }"
                >
                  <span v-html="background.title"></span>
                </div>
            </div>   
        </div> 

        <div class="section section__main">
            <div class="uk-container uk-container-large">
                <div class="smell_main">
                    <div class="smell_main__top">
                        Важно ответственно подойти к выбору аромата, которому предстоит наполнить салон вашего авто. Во-первых, не следует забывать о том, что любой запах имеет свою силу воздействия на человеческую психику. Каждый человек знает, какие ароматы действуют на него расслабляющее, а какие тонизируют.
                    </div>
                    <div class="smell_row">
                        <div class="column_small">
                            <div class="smell segment">Если водитель постоянно ощущает тяжелейшую усталость, имеет смысл купить бодрящий ароматизатор для автомобиля: тонизирующие запахи не позволят расслабиться за рулем и потерять концентрацию. Чаще всего, такой ароматизатор будет пахнуть цитрусовыми или морозным утром.</div>
                        </div>
                        <div class="column_wide">
                            <div class="smell segment">В том случае, когда нужно побороть другую проблему — нервозность и ощущение «будто на иголках», следует подумать о том, какие запахи смогут оказать релаксирующее воздействие. Возможно, имеет смысл приобрести ароматизатор с медовым или цветочным ароматом. Однако у каждого человека свои реакции на тот или иной запах, а потому сложно дать какой-то универсальный «рецепт».</div>
                        </div>
                    </div>
                    <div class="smell_main__description">
                        По своему воздействию запахи делятся на благоприятные и неблагоприятные.
                    </div>

                    <div class="smell_block__table1">
                        <div class="block_mobile">Благоприятные</div>
                        <div class="smell_block1">   
                            <div class="sidebar_desktop">
                                <div class="sidebar_desktop__blue">Благоприятные</div>
                                <div class="sidebar_desktop__white">
                                    <div class="block__white"></div>
                                </div>
                            </div>
                            <div class="main_desktop">
                                <div class="main_desktop_header">
                                    <div class="main_desktop__left">Аромат</div>
                                    <div class="main_desktop__right">Реакция</div>
                                </div>
                                <div class="main_desktop_text">  
                                    <div class="desktop_text_1">Корица</div>
                                    <div class="desktop_text_2">Стимулирует реакцию. Повышает внимание водителей, делает их менее раздрожительными</div>
                                </div>
                                <div class="main_desktop_text">  
                                    <div class="desktop_text_left">Цитрусовые</div>
                                    <div class="desktop_text_right">Снимают усталость, стимулируют реакцию</div>
                                </div>
                                <div class="main_desktop_text">  
                                    <div class="desktop_text_left">Морские, хвойные</div>
                                    <div class="desktop_text_right">Стимулируют реакцию, успокаивают нервы. Немного расслабляют мышцы, что особо помогает в долгих поездках</div>
                                </div>
                                <div class="main_desktop_text">  
                                    <div class="desktop_text_left">Мята</div>
                                    <div class="desktop_text_right">Снимает раздражительность</div>
                                </div>
                                <div class="main_desktop_text">  
                                    <div class="desktop_text_3">Роза</div>
                                    <div class="desktop_text_4">Стимулирует память, вызывает повышенную способность к сосредоточению</div>
                                </div>
                            </div>
                        </div> 
                        <div class="block_mobile__bottom">Неблагоприятные</div>
                        <div class="smell_block1">   
                            <div class="sidebar_desktop">
                                <div class="sidebar_desktop__blue">Неблагоприятные</div>
                            </div>
                            <div class="main_desktop">
                                <div class="main_desktop_text">  
                                    <div class="desktop_text_1">Ромашка, Жасмин, Лаванда</div>
                                    <div class="desktop_text_2">Расслабляют и притупляют реакцию</div>
                                </div>
                                <div class="main_desktop_text">  
                                    <div class="desktop_text_left">Аромат полевых трав</div>
                                    <div class="desktop_text_right">Вызавают ностальгию и притупляют реакцию</div>
                                </div>
                                <div class="main_desktop_text">  
                                    <div class="desktop_text_3">Кулинарные ароматы</div>
                                    <div class="desktop_text_4">Раздражают, вызывают чувство голода</div>
                                </div>
                                
                            </div>
                        </div> 



                        <div class="smell_text__bottom">
                            Ароматизаторы для автомобиля - не слишком чувствительные аксессуары, однако не стоит эксплуатировать их в неблагоприятных условиях. Висящая под нещадно палящим июльским солнцем «елочка» может стать источником аллергической реакции. Всему свое место и время, поэтому подбирайте ароматизаторы по сезону.
                        </div>
                    </div>  
                 
                </div>
            </div>  
        </div>
        <MouseAnim v-if="isDevice == 'desktop'"/>
    </div>   
</template>


<script>
import MouseAnim from '../components/MouseAnim'
import BackgroundsJson from '@/assets/data/backgrounds.json'

export default {
  components: {
    MouseAnim,
  },
  props: {
    isDevice: null
  },
  computed: {
    background() {
      const backgrounds = BackgroundsJson.filter(item => {
        return item.route === this.$route.path
      })
      if (backgrounds) {
        let b = backgrounds[Math.floor(Math.random() * backgrounds.length)]
        this.$emit('updateHeaderTheme', {theme: b.header})
        return b
      }
      return null
    },
    appName() {
      return process.env.VUE_APP_NAME
    }
  },
}
</script>