<template>
  <div
      v-if="!loadInitData"
      :class="{
        section__filtration: !header,
        header__filtration: header,
        black: black
      }"
  >
    <div
        class="container__filters"
        v-show="showTab('type')"
        :class="{'animated slideInUpShort' : !header, 'animated slideInDown' : header}"
    >
      <div class="filters filters__title" v-if="!mobile && device != 'tablet'">
        По типу упаковки:
        <!-- uncomment the lines below if needed to add an additional filters and comment the line above -->
        <!-- <p :class="{ 'active' : packageTab === 'type' }" @click="changeFilter('tab', 'type')">По типу упаковки:</p> -->
        <!-- <p :class="{ 'active' : packageTab === 'package' }" @click="changeFilter('tab', 'package')">По виду упаковки:</p> -->
      </div>
      <div class="filters filters__title"  v-else-if="device === 'tablet'"   >
        <p :class="{ 'active' : curTab === 'type' }" @click="curTab = 'type'">По типу упаковки:</p>
        <p :class="{ 'active' : curTab === 'category' }" @click="curTab = 'category'">По аромату:</p>
      </div>
      <div
          class="filters filters__selected"
          v-else
          @click.stop="dropdown = openDropdown('packageType')"
      >
        <p class="uk-text-truncate">
          {{packageTypeFilter.title}}
        </p>
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 24 24"
             fill="none"
             stroke="#262626"
             stroke-width="2"
             stroke-linecap="round"
             stroke-linejoin="round">
          <path d="M6 9l6 6 6-6"/>
        </svg>
      </div>
      <div
        class="filters__types"
        :class="{
          'filters__dropdown' : mobile,
          'active' : dropdown === 'packageType'
        }"
      >
        <template v-for="(item, index) in packageTypes">
          <button
              v-if="includesType(item)"
              class="filters filters__type"
              :key="index"
              :class="{ 'filters__type-active' : item.id === packageTypeFilter.id }"
              @click="changeFilter('type', item)"
          >
            {{ item.title }}
          </button>
        </template>
      </div>
      <!-- <div class="filters__types" v-show="packageTab === 'package'">
        <button
          class="filters filters__type"
          v-for="(item, index) in packages"
          :key="index"
          :class="{ 'filters__type-active' : item.id === packageFilter.id }"
          @click="changeFilter('package', item)"
        >
          {{item.title}}
        </button>
      </div> -->
    </div>
    <div
        class="container__filters"
        v-show="showTab('category')"
        :class="{'animated_slow slideInUpShort' : !header, 'animated slideInDown' : header}"
    >
      <div class="filters filters__title" v-if="!mobile && device != 'tablet'">
        По аромату:
      </div>
      <div class="filters filters__title"  v-else-if="device === 'tablet'">
        <p
            :class="{ 'active' : curTab === 'type' }"
            @click="curTab = 'type'"
        >
          По типу упаковки:
        </p>
        <p
            :class="{ 'active' : curTab === 'category' }"
            @click="curTab = 'category'"
        >
          По аромату:
        </p>
      </div>
      <div class="filters filters__selected" v-else @click.stop="dropdown = openDropdown('category')">
        <p class="uk-text-truncate">{{categoryFilter.title}}</p>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#262626"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round">
          <path d="M6 9l6 6 6-6"/>
        </svg>
      </div>
      <div
          class="filters filters__types"
          :class="{
            'filters__dropdown' : mobile,
            'active': dropdown === 'category'
          }"
      >
        <button
          class="filters filters__type"
          v-for="(item, index) in categories"
          :key="index"
          :class="{'filters__type-active' : item.id === categoryFilter.id}"
          @click="changeFilter('category', item)"
        >
          {{ fixTitleCategory(item.title) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../event-bus'
import { mapState } from 'vuex'
import utils from '../utils'

export default {
  name: 'catalog-page',
  props: {
    black: {
      type: Boolean,
      default: false
    },
    header: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: Boolean,
      default: false
    },
    device: null,
  },
  data() {
    return {
      curTab: 'type',
      packageFilter: {'id': 0, 'title': 'Все'},
      packageTypeFilter: {'id': 0, 'title': 'Все'},
      categoryFilter: {'id': 0, 'title': 'Все'},
      packages: [{'id': 0, 'title': 'Все'}],
      packageTypes: [
        {'id': 0, 'title': 'Все'},
        {'id': 1, 'title': 'Универсальные'},
        {'id': 2, 'title': 'На кондиционер'},
        {'id': 3, 'title': 'Жидкие'},
        {'id': -1, 'title': 'Аксессуары'}
      ],
      categories: [{ 'id': 0, 'title': 'Все'}],
      dropdown: null,
      loadInitData: true,
      
    }
  },
  watch: {
    packageTypeFilter() {
      this.$emit('changeParams', {
        key: this.packageTab,
        item: this.packageTypeFilter
      })
    },
    packageFilter() {
      this.$emit( 'changeParams', {
        key: 'package',
        item: this.packageFilter
      })
    },
    categoryFilter() {
      this.$emit( 'changeParams', {
        key: 'category',
        item: this.categoryFilter
      })
    },
    filters (newValue) {
      if (newValue.packageTab === 'type') {
        this.packageTab = 'type'
        if (this.packageTypeFilter.id != newValue.packageType.id) {
          this.packageTypeFilter = newValue.packageType
        }
      }
      // else if (newValue.packageTab === 'package') {
      //   this.packageTab = 'package'
      //   if (this.packageFilter.id != newValue.package.id) this.packageFilter = newValue.package
      // }
      if (this.categoryFilter.id != newValue.category.id) {
        this.categoryFilter = newValue.category
      }
    }
  },
  computed: {
    ...mapState(['filters']),
  },
  methods: {
    showTab(tab) {
      if (this.device != 'tablet') return true
      if (this.curTab === tab) return true
      return false
    },
    includesType(item) {
      return item.id < 1 || this.packages.some((pack) => pack.type === item.id)
    },
    openDropdown(tab) {
      if (this.dropdown === tab) return false
      return tab
    },
    clickOutside() {
      this.dropdown = null
    },
    defaultFilters() {
      // this.$store.commit('changeFilter', {
      //   filter: 'tab',
      //   content: 'package'
      // })
      this.$store.commit('changeFilter', {
        filter: 'type',
        content: this.packageTypes.find(
            elem => elem.title.toLowerCase() === 'универсальные'
        )
      })
      this.$store.commit('changeFilter', {
        filter: 'category',
        content: this.categories.find(
            elem => elem.title.toLowerCase() === 'успокаивающие ароматы' )
      })
    },
    changeFilter(filter, item) {
      this.$store.commit('changeFilter', {
        filter: filter,
        content: item
      })
      this.dropdown = null

      if (!this.header) {
        let section = document.querySelector('.section__filtration')
        if (section) {
          window.scrollTo({
            top: section.offsetTop - 150,
            behavior: "smooth"
          });
        }
      }
    },
    getPackages: async function() {
      const { fetchData } = utils()
      const typeQuery = this.$route.query.type
      const packQuery = this.$route.query.package

      const accessory = {
        'id': -1,
        'title': 'Аксессуары'
      }

      let response = await fetchData('get', 'packages')
      this.packages = this.packages.concat(response?.data.reverse())

      /** set filter package **/
      if (!isNaN(packQuery)) {
        const packageTemp = this.packages.find(pack => {
          return pack.id == packQuery
        })
        if (packageTemp) {
          this.packageFilter = packageTemp
          this.changeFilter('package', packageTemp)
        }
      }
      /** end **/

      /** set filter package type + type accessory **/
      this.packages.push(accessory)

      if (!isNaN(typeQuery)) {
        const typeTemp = this.packageTypes.find(pack => {
          return pack.id == typeQuery
        })
        if (typeTemp) {
          this.packageTypeFilter = typeTemp
          this.changeFilter('type', typeTemp)
        }
      }

      if (typeQuery === 'accessories') {
        this.packageTypeFilter = accessory
        this.changeFilter('type', accessory)
      }
      /** end **/
    },
    getCategories: async function() {
      const { fetchData } = utils()

      let response = await fetchData('get', 'categories')
      this.categories = this.categories.concat(response?.data.reverse())

      /** set filter category **/
      if (this.$route.query.category) {
        const categoryTemp = this.categories.find(category => {
          return category.id == this.$route.query.category
        })
        if (categoryTemp) {
          this.categoryFilter = categoryTemp
          this.changeFilter('category', this.categoryFilter)
        }
      }
      /** end **/
    },
    fixTitleCategory(title) {
      if (!this.header) {
        const pos = title.indexOf('аромат') - 1
        return pos > 0 ? title.substring(0, pos) : title
      }
      return title
    },
    async initData() {
      await this.getPackages()
      await this.getCategories()
      this.loadInitData = false
    }
  },
  mounted() {
    this.initData()
    EventBus.$on('defaultFilters', this.defaultFilters)
  },
}
</script>